import React, { Component } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'


import { MyLoader,ShowFiltersResult, ShowNoItemsYet, ButtonGoUp, ButtonShowMore, ButtonShowAll, ButtonOrderFirst, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { removeFromMatriz, addToMatrizOfArrayItemsByDate, resizeNavBar, openUrl, checkFileExistsFromFireStorage, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl, deleteFileFromFireStorage, fileExistsOnArray } from '../CommonFunctions'
import { ImageGallery2, ImageDialog } from './ImageGallery2'


//reveal-effect
import Bounce from 'react-reveal/Bounce';

//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";


import { withTranslation, useTranslation } from 'react-i18next';


const FEATURE_ID = 3
const LIMIT_TO_LAST = 500

const RENDER_SIZE_INCREMENT = 30




class CameraPictures extends Component {


    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            pictures: null,
            picturesFiltered: [],
            picturesOrderRecentFirst: true,

            selectedPicture: "null",

            filterDate: null,

            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,

            renderSize: 50
        }

    }


    getOriginalDbRef = () => {
        //CREO el puntero

        if (this.context.activeDevice.statusCode != 1)//DUMMY PATH
            return miFireBase.database().ref(this.context.dbPathDummy).child("MDfotosCamara")

        return miFireBase.database().ref(this.context.dbPath).child("MDfotosCamara")//ORIGINAL PATH

    }


    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        const dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        dbRef.once('value', snap => {
            console.log("****CAMERA pictures dbRef.once VALUE****" + this.context.activeDevice.imei)

            let picturesAux = []


            snap.forEach((snap) => {

                let pic = ({
                    // original: '...', renderItem: renderPicture,
                    // thumbnail: '...', renderThumbInner: renderThumb,
                    granted: featureGranted,

                    key: snap.key,
                    url: snap.val().url,
                    fileName: snap.val().fileName,
                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView) && this.state.totalObjectsUnread > 0,
                    deleteObject: this.deleteObject
                })


                checkFileExistsFromFireStorage(miFireBase, pic, this.deleteObject)

                if (!fileExistsOnArray(pic, picturesAux)) {//Como es acumulativo, debo chequear
                    picturesAux.push(pic)
                } else {
                    this.deleteObject(pic.key)
                }


            })
            sortArrayByFechaHora(picturesAux)//solo se hace al principio


            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = picturesAux[picturesAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------


            sortArrayByRecentFirst(picturesAux, this.state.picturesOrderRecentFirst)

            this.setState({
                pictures: picturesAux,
                picturesFiltered: picturesAux,

                lastUpdate: lastUpdate,
                totalObjects: picturesAux.length,
            })

        }).then(() => {
            //Luego que se hizo todo lo anterior...
            const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
            if (dateFilterFromUrl)
                this.handleCalendarChange(dateFilterFromUrl)

        })



    }





    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.pictures
            const filteredArray = this.state.picturesFiltered

            if (originalArray) {
                deleteFileFromFireStorage(miFireBase, originalArray[originalArray.map((c) => c.key).indexOf(objKey)]?.url)//elimino de firebaseStorage
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado


                //Actualizo estados
                this.setState({
                    pictures: originalArray,
                    picturesFiltered: filteredArray,

                    totalObjects: originalArray.length,
                })
            }
        }
    }



    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }


    handleCalendarChange = (date) => {

        if (date) {

            let picturesFilteredAux = []

            this.state.pictures.map(pic => {
                if (pic.fecha == format(date, 'yyyy-MM-dd'))
                    picturesFilteredAux.push(pic)
            })


            sortArrayByRecentFirst(picturesFilteredAux, this.state.picturesOrderRecentFirst)

            this.setState({
                filterDate: date,
                picturesFiltered: picturesFilteredAux
            })

        } else {
            this.handleShowAllClick()//vuelvo a todos

        }

    }



    handleOrderChange = () => {

        this.setState({//lo hago con ambas
            picturesFiltered: this.state.picturesFiltered.reverse(),
            picturesOrderRecentFirst: !this.state.picturesOrderRecentFirst
        })

    }


    handleShowAllClick = () => {

        this.setState({
            filterDate: null,
            picturesFiltered: this.state.pictures
        })
    }


    handlePictureClick = (item) => {
        if (this.state.featureGranted) {
            openUrl(item.url)
        } else {
            window.$('#ShowImageDialog').modal('hide')
            this.props.history.push('/buy');
        }
    }
    handlePictureDelete = (item) => {
        window.$('#ShowImageDialog').modal('hide')
        if (this.state.featureGranted) {
            this.deleteObject(item.key)
        } else {
            this.props.history.push('/buy');
        }
    }
    handleThumbClick = (picture) => {
        this.setState({ selectedPicture: picture })
        window.$('#ShowImageDialog').modal('show')
    }




    render() {
        const { t } = this.props;

        if (!this.state.pictures) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t('CameraPictures.title')}</h3>
                        <MyLoader text={t('CameraPictures.loading')}/>
                    </div>
                </div>
            )


        } else {

            if(this.state.pictures.length==0)return <ShowNoItemsYet size={this.state.pictures.length} itemName='pictures' />


            return (
                <div onClick={() => resizeNavBar()}>

                    <ImageDialog image={this.state.selectedPicture} handlePictureClick={this.handlePictureClick} handlePictureDelete={this.handlePictureDelete} />

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t('CameraPictures.title')}</h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title=" pictures" limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ pictures: [], picturesFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />


                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-dark  rounded-lg p-2">
                        <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.pictures} />
                        <ButtonShowAll size1={this.state.pictures} size2={this.state.picturesFiltered} handleShowAllClick={this.handleShowAllClick} />
                    </div>
                    {/* *********** */}


                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.picturesFiltered.length} filterName='date' showAll={this.handleShowAllClick} />


                    <div className="row">
                        <div className="col-12 mb-3">


                            <React.Fragment>
                                {this.state.picturesFiltered.length > 0 &&
                                    <div>
                                        <ButtonOrderFirst length={this.state.picturesFiltered.length} order={this.state.picturesOrderRecentFirst} handleOrderChange={this.handleOrderChange} />
                                        <ImageGallery2 items={this.state.picturesFiltered.slice(0, this.state.renderSize)} handleThumbClick={this.handleThumbClick} />
                                    </div>
                                }
                            </React.Fragment>


                            <ButtonGoUp arrayLength={this.state.picturesFiltered.length} maxLength={20} />
                            <ButtonShowMore arrayLength={this.state.picturesFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />


                        </div>
                    </div>

                </div>
            )

        }
    }
}
CameraPictures.contextType = MiContexto
CameraPictures = withTranslation()(CameraPictures);
export default withRouter(CameraPictures);


