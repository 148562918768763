import React, { Component } from 'react'

import MiContexto from '../MiContexto'
import UseWebService from '../UseWebService'

import { MyLoader } from '../CommonComponents'
import { withTranslation } from 'react-i18next';


import CanvasJSReact from '../canvasjs/canvasjs.react'
const CanvasJSChart = CanvasJSReact.CanvasJSChart;


const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

class AmountsByFeature extends Component {



    constructor(props) {
        super(props)

        this.state = ({

            selectedMonth: null,
            selectedFeature: null,

            wsAllFeatures: null,

            wsFeatureAmounts: null,
        })
    }


    componentDidMount() {


        let defaultMonth = ({ title: monthNames[(new Date).getMonth()] + " - " + (new Date).getFullYear(), value: (new Date).getMonth() }) //Por defecto el mes actual es el inicial
        let defaultFeature = ({ title: "SMS Messages", value: 1 }) //Por defecto selecciono SMS messages


        const { targetFeature } = this.props
        if (targetFeature) defaultFeature = targetFeature

        this.setState({ selectedMonth: defaultMonth, selectedFeature: defaultFeature }, () => this.loadDataFromWebService())

    }




    loadDataFromWebService = () => {


        const operation1 = "getFeaturesInf"
        UseWebService("operation=" + operation1).then(resp => {
            if (resp.statusCode == 200) {
                const arrayFeaturesInf = new Array(28)
                resp.data.map(ft => { if (ft) arrayFeaturesInf[ft.featureId] = ft })
                this.setState({ wsAllFeatures: arrayFeaturesInf })
            }
        })



        const operation2 = "getDataAmountsByFeature"
        this.setState({ wsFeatureAmounts: null })
        UseWebService("operation=" + operation2 + "&imei=" + this.context.activeDevice.imei + "&featureId=" + this.state.selectedFeature.value + "&month=" + (parseInt(this.state.selectedMonth.value) + 1)).then(resp => {

            if (resp.statusCode == 200) {
                //Construyo el array (cada posicion representa el numero de dia del mes)
                const arrayFeatureAmounts = new Array(31)
                for (let i = 1; i < arrayFeatureAmounts.length; i++)arrayFeatureAmounts[i] = 0

                resp.data.map(ft => { if (ft) arrayFeatureAmounts[ft.day] = parseInt(ft.items) })
                this.setState({ wsFeatureAmounts: arrayFeatureAmounts })

            } else {
                this.setState({ wsFeatureAmounts: [] })
            }
        })


    }


    handleSelect = () => {
        const selectMonthControl = document.querySelector("#selectMonthControl")
        const monthValue = selectMonthControl[selectMonthControl.selectedIndex].value
        const monthTitle = selectMonthControl[selectMonthControl.selectedIndex].text

        let targetMonth = ({ title: monthTitle, value: monthValue })


        let targetFeature = this.props.targetFeature

        if (!targetFeature) {
            const selectFeatureControl = document.querySelector("#selectFeatureControl")
            const featureValue = selectFeatureControl[selectFeatureControl.selectedIndex].value
            const featureTitle = selectFeatureControl[selectFeatureControl.selectedIndex].text

            targetFeature = ({ title: featureTitle, value: featureValue })
        }


        this.setState({
            selectedMonth: targetMonth,
            selectedFeature: targetFeature
        },
            () => this.loadDataFromWebService())


    }


    render() {
        const { t } = this.props; //función de traducción


        if (!this.state.wsAllFeatures) {

            return (
                <div className="container  text-center mt-4">
                    <MyLoader text={t('DataHistoryFeatures.loading')}/>
                </div>
            )


        } else {
            return (

                <React.Fragment>

                    <div className="row justify-content-center">

                        {/* Si hay wsFeatures ya cargada permito seleccionarlas */}
                        {this.state.wsAllFeatures &&
                            <div className="col-12 col-md-6 mt-2 ">
                                <div className="text-warning mr-2 mt-2 mb-0">{t("DataHistoryFeatures.feature")}</div>

                                <select defaultValue={this.state.selectedFeature && this.state.selectedFeature.value} className="form-control miSelect" id="selectFeatureControl" onChange={this.handleSelect} >
                                    {
                                        this.state.wsAllFeatures.map(ft => {

                                            let ftName = ft?.name
                                            if (this.context.isBrowserSpanish)  ftName = ft?.name_es

                                            if (ft) {
                                                return (<option key={ft.featureId} value={ft.featureId}>{ftName} </option>)
                                            }
                                        })
                                    }

                                </select>
                            </div>
                        }


                        <div className="col-12 col-md-6 mt-2">
                            <div className="text-warning mr-2 mt-2 mb-0">{t("DataHistoryFeatures.month")}</div>

                            {this.state.selectedMonth && <select defaultValue={this.state.selectedMonth.value} className="form-control miSelect" id="selectMonthControl" onChange={this.handleSelect} >
                                {
                                    monthNames.map((m, index) => {
                                        if ((new Date).getMonth() >= index)//limito hasta mes actual
                                            return (<option key={index} value={index}> {m + " - " + (new Date).getFullYear()}  </option>)
                                    })
                                }
                            </select>}
                        </div>

                    </div>




                    {/* ############################################################################## */}
                    {!this.state.wsFeatureAmounts ?
                        // CARGANDO wsFeatureAmounts...

                        <div className="container  text-center mt-4">
                            <MyLoader text={t('DataHistoryFeatures.loading2')}/>
                         </div>

                        :

                        // SE CARGO ok!
                        <div className="row ">
                            <div className="col-12 ">

                                <div className="mt-3">
                                    <GraphFeatureAmounts amountsArray={this.state.wsFeatureAmounts}
                                        selectedMonth={this.state.selectedMonth}
                                        featureTitle={this.state.selectedFeature.title} />
                                </div>

                            </div>
                        </div>

                    }

                </React.Fragment>

            )

        }

    }


}
AmountsByFeature.contextType = MiContexto
AmountsByFeature = withTranslation()(AmountsByFeature);
export default AmountsByFeature;





function GraphFeatureAmounts(props) {

    let { amountsArray, featureTitle, selectedMonth } = props


    if (amountsArray.length > 0) {

        const thisMonth = (new Date).getMonth()

        let maxLengthCover = amountsArray.length
        if (thisMonth == selectedMonth.value)//Si el mes seleccionado coincide con mes actual, limito el rango de dias de la grafica hasta el dia actual
            maxLengthCover = (new Date).getUTCDate() //numero de dia actual



        const graphPoints = []
        for (let i = 1; i <= maxLengthCover; i++)
            graphPoints.push({ x: i, y: amountsArray[i] })

        const graphOptions = {
            animationEnabled: true,
            theme: "dark2",
            title: {
                text: featureTitle + " on " + selectedMonth.title
            },
            axisX: {
                title: "Day ",
                prefix: "d",
                interval: 2
            },
            axisY: {
                title: "Items ",
                prefix: "",
            },
            data: [{
                toolTipContent: "{x} " + selectedMonth.title.replace("-", "") + ": {y} " + featureTitle,
                type: "line",
                showInLegend: true,
                legendText: "Days",
                dataPoints: graphPoints,
                color: "#60f133"

            }]
        }

        return (
            <CanvasJSChart options={graphOptions} />
        )


    } else {

        return (
            <div className="text-warning text-center mt-1">{"No " + featureTitle + " on " + selectedMonth.title}</div>
        )

    }


}














