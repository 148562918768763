import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'

import MiContexto from './MiContexto'


//Para GoogleAnalytics
import ReactGA from 'react-ga';
ReactGA.initialize("UA-112018209-8");
//-------

export default function usePageViews() {
  let location = useLocation()

  const user = useContext(MiContexto).user

  useEffect(
    () => {
      let currentPage = location.pathname
      if (user.isDemoUsr) currentPage += "/DEMO-USR"
      
      ReactGA.pageview(currentPage)
    },
    [location]
  )

  return null
}

