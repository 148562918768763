import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import firebase from 'firebase/compat/app';

const FIREBASE_CONFIG = {
    apiKey: "AIzaSyBVQYU9-rygrs-NQhOdhtY1iC02JOq_l0U",
    authDomain: "catwatchful-e03b8.firebaseapp.com",
    databaseURL: "https://catwatchful-e03b8-2.firebaseio.com/",
    projectId: "catwatchful-e03b8",
    storageBucket: "catwatchful-e03b8.appspot.com",
    messagingSenderId: "290254967732",
    appId: "1:290254967732:android:1be5a596e0649f3e",
    measurementId: "G-4X1YYWJREQ"
}
const miFireBase = firebase.initializeApp(FIREBASE_CONFIG)
export default miFireBase;
