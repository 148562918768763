import React, { useEffect, useState, useContext } from 'react';
import { useHistory} from 'react-router-dom';
import Moment from 'react-moment';

import { highlightFilter, GetCurrentDateFromArray } from '../../CommonComponents'
import { getRandomNumber, isToday,isYesterday, isValidDate } from '../../CommonFunctions';
import './Skin.css'
import MiContexto from '../../MiContexto'


export function FbChats(props) {

    const history = useHistory ();

    const { user } = useContext(MiContexto)
    const { chats, handleChatChange, deleteChat, totalObjectsUnread ,lastUpdate} = props


    // const wppAbout = "about"
    // const profilePic = "https://www.w3schools.com/howto/img_avatar.png"


    const getUnreadColor = (unread) => {
        if (unread) return "#03d562"
        return "#4c4f50"
    }

    const getCircularReveal = (unread) => {
        if (!parseInt(unread) >= 1) return null
        return (
            <div className='ml-2 font-weight-bold bg-light mt-1' style={{ color: "#008069", borderRadius: "50%", width: "13px", height: "13px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: "0.8em" }}>{unread}</span>
            </div>
        )
    }

    const TypeStyle = (props) => {
        const { type, isUnread } = props
        if (isUnread) return null

        //Entregado
        if (type === 1 || type === 2) return <span className='text-secondary' style={{ fontSize: "10px" }}><i className='fa fa-check ' /><i className='fa fa-check' /></span>
        //Entregado y LEIDO
        if (type === 3 || type === 4) return <span className=' text-primary ' style={{ fontSize: "10px" }}><i className='fa fa-check' /><i className='fa fa-check' /></span>

        return null
    }




    return (
        <div>

            {/* CABEZERA CHAT */}
            <div className='w-100 rounded-top' style={{ backgroundColor: "#0084ff" }}>
                <div className='d-flex justify-content-between pt-2 px-1 py-3'>
                    <div className='text-left ml-1'>

                        <div className='font-weight-bold ml-2' style={{ fontSize: "14px" }}>
                            {/* <img src={profilePic} className="rounded-circle" style={{ width: "35px", height: "35px" }} /> */}
                            <div style={{ fontSize: "1.0em"  }}><img src={'images/fbmsgr.png'} className="" style={{ width: "25px", height: "25px" }} /> Facebook</div>
                            <div className='small ml-4 font-italic' style={{ lineHeight: "7px", fontSize: "10px" }}>UPDATE: <Moment date={lastUpdate} format="dddd Do, MMM, HH:mm A" /></div>
                        </div>

                    </div>
                    <div className='d-flex justify-content-between '>
                        <i className='fa fa-phone mx-3' />
                        <i className='fa fa-video-camera' />
                        <i className='fa fa-exclamation-circle mx-3' />
                    </div>
                </div>



                <div className='d-flex justify-content-between px-3 small font-weight-bold pb-1'>
                    <div className='d-flex' style={{ borderBottom: "3px solid #fff" }}><i style={{ fontSize: "16px" }} className='fa fa-users mx-2' /> Chats  {getCircularReveal(totalObjectsUnread)}  </div>
                    <div className='d-flex' style={{ fontSize: "12px" }}>Historias{getCircularReveal(getRandomNumber(0, 5))}</div>
                    <div className='d-flex' style={{ fontSize: "12px" }}>Llamadas {getCircularReveal(getRandomNumber(0, 3))}</div>
                </div>
            </div>
            {/* *********************** */}

            <ul className="list-group list-group-flush rounded-bottom bg-light" style={{ height: "100%" , paddingBottom:"20px", overflowX: "hidden" }}>
                {chats.map((chat, index) => {

                    //------
                    let chatID = chat.chatID
                    let nombre = chat.nombreContacto

                    let cantMessages = chat.cantMessages

                    let cantMessagesUnread = chat.cantMessagesUnread
                    if (cantMessagesUnread == true) cantMessagesUnread = 1

                    if (user.isDemoUsr) cantMessagesUnread = getRandomNumber(0, 15)

                    let snipet = chat.snipet
                    let fecha = chat.fecha
                    let hora = chat.hora
                    //-------

                    if (!chat.picture) chat.picture = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"

                    let blur=""
                    if(chat.isDummy) blur="divBlur"

                    return (
                        <div key={index} className={`d-flex justify-content-between my-2 mx-1 ${blur}`} onClick={() => { if(!chat.isDummy){handleChatChange(chatID)}else{history.push ('/buy')} }} >
                            <div className='d-flex '>

                                <img src={chat.picture} className="rounded-circle " style={{ width: "35px", height: "35px" ,minWidth:'35px'}} />
                                <div className='small'>
                                    <div className='mx-2 font-weight-bold text-dark'>{nombre} </div>
                                    <div className='mx-2 ' style={{ color: "#4c4f50" }}><TypeStyle type={chat.type} isUnread={chat.unread} /> {snipet?.substring(0, 50)}</div>
                                </div>
                            </div>

                            <div className='small ' >

                                <div className='small text-center' style={{ color: getUnreadColor(chat.unread) }}>
                                    <ShowItemDate fecha={fecha} hora={hora} />
                                </div>

                                {/* <i className=" ml-2 fa fa-trash-o  text-danger " onClick={() => deleteChat(nombre)} /> */}

                                <div className='d-flex justify-content-center mt-0 '>

                                    {/* <div className=' ml-2 font-weight-bold' style={{ color: "#fff", backgroundColor: "#03d562", borderRadius: "50%", width: "17px", height: "17px", fontSize: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        {cantMessages}
                                    </div> */}

                                    {cantMessagesUnread > 0 ?
                                        <div className=' ml-2 font-weight-bold' style={{ color: "#fff", backgroundColor: "#0084ff", borderRadius: "50%", width: "17px", height: "17px", fontSize: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            {cantMessagesUnread}
                                        </div>
                                        :
                                        <div className=' ml-2 font-weight-bold' style={{ color: "#0084ff", backgroundColor: "#fff", borderRadius: "50%", width: "17px", height: "17px", fontSize: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            {cantMessages}
                                        </div>
                                    }



                                </div>

                                {/* {cantMessagesUnread > 0 &&
                                    <div className='text-danger ' ><span className='badge badge-danger pt-1'>{cantMessagesUnread} Nuevos</span> <span ></span></div>
                                } */}



                            </div>

                        </div>
                    )

                })}
            </ul>


        </div>



    )
}


const ShowItemDate = (props) => {

    const { fecha, hora } = props

    if (isValidDate(fecha + " " + hora)) {
        if (isToday(fecha)) {
            return (<React.Fragment> <span className="mr-1"></span> <Moment date={fecha + " " + hora} format=" HH:mm" /> </React.Fragment>)
        } else if (isYesterday(fecha)) {
            return (<React.Fragment> <span className="mr-1"></span> Ayer </React.Fragment>)
        } else {
            return (<Moment date={fecha + " " + hora} format="L" />)
        }
    } else {
        return (fecha + " " + hora)
    }

}




export function FbConversation(props) {

    const { messages, filter, featureGranted, deleteChat, deleteObject } = props
    const history = useHistory ();


    useEffect(() => {
        var objDiv = document.getElementById("fbConversation");
        if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
    }, [messages])



    if (!messages || messages.length === 0) return null


    const wppBackground = './images/wppBackground.png'
    const chatPicture = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"


    const contact = messages[0]//tomo uno cualquiera
    const cantMessaes = messages.length

    let nombreContacto = contact.nombreContacto
    if (!nombreContacto) nombreContacto = contact.numero

    let unReadMessages = messages.filter(msg => msg.unRead === true).length



    return (
        <div>

            {/* CABEZERA CONVERSACION */}
            <div>
                <div className='w-100 rounded-top py-3 text-dark' style={{ backgroundColor: "#fff" }}>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex' style={{alignItems:"center"}} >
                            <i className='fa fa-arrow-left mx-2 pt-2' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                            <img src={chatPicture} className="rounded-circle " style={{ width: "30px", height: "30px",minWidth:'30px' }} />
                            <div style={{ marginTop: "-6px" }}>
                                <div className='font-weight-bold ml-2 pt-1' style={{ fontSize: "0.8em", fontWeight:"700"   }}>{nombreContacto}</div>
                                <div className='small ml-2' style={{ lineHeight: "6px" , fontSize: "0.7em" }}>
                                    {cantMessaes} Messages
                                    {unReadMessages > 0 && <span className='badge ml-1 ' style={{ backgroundColor: "#03d562" }} >{unReadMessages} Nuevos</span>}
                                </div>
                            </div>


                        </div>
                        <div className='d-flex' >
                            <i className='fa fa-video mx-3' />
                            <i className='fa fa-phone' />
                            {/* <i className='fa fa-ellipsis-v mx-3' /> */}
                            <i className='text-danger fa fa-trash mx-3' onClick={() => deleteChat(nombreContacto)} />
                        </div>
                    </div>

                </div>
            </div>
            {/* ******************* */}


            <div id="fbConversation" className='text-dark py-2 px-2 rounded-bottom' style={{
                backgroundColor: "#f6f6f6", height: "500px", overflowX: "hidden",
                backgroundAttachment: "fixed", backgroundSize: "100%"
            }}>


                {messages.map((msg, index) => {

                    //------
                    let key = msg.key
                    let fecha = msg.fecha
                    let hora = msg.hora
                    let nombreContacto = msg.nombreContacto
                    let texto = msg.texto

                    //let tipo = msg.tipo
                    let tipo = getRandomNumber(0, 1) === 0 ? "sent" : "received"
                    let unRead = msg.unRead
                    //-------

                    let isCall = false
                    if (texto.includes("Llamada") || texto.includes("llamadas") || texto.includes("Llamando") || texto.includes("Call") || texto.includes("calls") || texto.includes("Calling")) isCall = true


                    let boxType = "bubble leftFb"
                    if (tipo === "sent") boxType = "bubble rightFb"

                    let blur = ""
                    if (!featureGranted) blur = "divBlur"

                    return (
                        <div key={key} className={`my-2 ${blur}`}>
                            <div className='d-flex justify-content-center'>
                                <GetCurrentDateFromArray array={messages} index={index} itemName="msg" innerStyle={{ maxWidth: "500px", fontSize: "13px", color: "#3b444d", paddingLeft: "10px", paddingRight: "10px", paddingTop: "3px", marginTop: "15px", borderRadius: "6px" }} />
                            </div>

                            {unRead && <span className='badge ml-1 text-light' style={{ backgroundColor: "#03d562" }} >Nuevo</span>}
                            <div className={`${boxType} small my-0 p-1`}>
                                <div style={{ fontWeight: 600, marginLeft: "3px" ,wordWrap: 'break-word'}}>
                                    {isCall && <span style={{ fontSize: "10px", marginRight: "5px" }}><i style={{ fontSize: "25px", color: "#000" }} className='fa fa-phone-square' /></span>}
                                    {highlightFilter(texto, filter)}
                                </div>
                                <div className='small text-dark text-right'>
                                    <ShowItemDate fecha={fecha} hora={hora} />
                                    {(tipo === "sent") && <span style={{ fontSize: "7px", marginLeft: "5px" }}><i className='fa fa-check' /><i className='fa fa-check' /></span>}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {!featureGranted &&
                <button className='btn btn-danger btn-block btn-sm' style={{ width: "100%", zIndex: "1", height: "37px" }}
                    onClick={() => history.push ('/buy')}>Desbloquear mensajes <i className='fa fa-eye ml-1' /></button>
            }
        </div>
    )
}


