import React, { Component } from 'react'
import { HashRouter, Switch, Route, Link } from "react-router-dom";


import MiContexto from '../MiContexto'

import AmountsByPeriod from './AmountsByPeriod'
import AmountsByFeature from './AmountsByFeature'

import { resizeNavBar } from '../CommonFunctions';

import { withTranslation, useTranslation } from 'react-i18next';


const MY_URL = "/datahistory"

 class DataHistory extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            currentTab: 1,
        })
    }


    componentDidMount() {
        resizeNavBar()
    }



    handleCurrentTabChange = (value) => {
        this.setState({ currentTab: value })
    }


    currentTabStyle = (value) => {
        if (this.state.currentTab == value) {
            return (
                " bg-info text-dark active nav-link my-1 "
            )

        } else {
            return (
                "bg-dark text-info nav-link my-1 "
            )
        }

    }


    render() {

        const { t } = this.props; //función de traducción

            return (
                <div onClick={()=>resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t("DataHistory.title")}</h3>
                        </div>
                    </div>


                    <ul className="nav nav-tabs mt-4">
                        <li className="nav-item">
                            <Link to={MY_URL}>
                                <div onClick={() => this.handleCurrentTabChange(1)} className={this.currentTabStyle(1)}><i className="fa fa-calendar fa-2x mr-1"></i>{t("DataHistory.byPeriod")}</div>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={MY_URL + "/byfeature"}>
                                <div onClick={() => this.handleCurrentTabChange(2)} className={this.currentTabStyle(2)}><i className="fa fa-cog fa-2x mr-1"></i>{t("DataHistory.byFeature")}</div>
                            </Link>
                        </li>
                    </ul>


                    <HashRouter>
                        <Switch>

                            <Route path={MY_URL} exact  >
                                <AmountsByPeriod />
                            </Route>
                            <Route path={MY_URL + "/byfeature"}>
                                <AmountsByFeature />
                            </Route>

                        </Switch>
                    </HashRouter>




                </div>

            )
        
    }
}
DataHistory.contextType = MiContexto
DataHistory = withTranslation()(DataHistory);
export default DataHistory;

