import React, { Component } from 'react'

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'

import { AlertMessage, ShowItemInfo } from '../CommonComponents'
import { resizeNavBar } from '../CommonFunctions'



//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"



//Calculos con fechas
import Moment from 'react-moment';

export default class ActiveUser extends Component {


    constructor(props) {
        super(props)

        this.state = {
            wsUserInfo: null,

            changePasswordAlertMessage: { message: "", title: "", isError: false },
            changePasswordIsLoading: false,
            closingAccountIsLoading: false,
        }

        this.handleChangePassClick = this.handleChangePassClick.bind(this)
        this.closeAccount = this.closeAccount.bind(this)

    }

    componentDidMount() {
        resizeNavBar()

        this.loadDataFromWebService()
    }


    loadDataFromWebService = () => {

        //Consulta1:
        const operation = "getUsrInf"
        UseWebService("operation=" + operation + "&email=" + this.context.user.email + "&firebase_uid=" + this.context.user.uid).then(resp => {
            if (resp.statusCode == 200)
                this.setState({ wsUserInfo: resp.data[0] })
        })

    }


    handleChangePassClick = () => {

        if (!this.context.user.isDemoUsr) {

            const oldPassword = this.state.wsUserInfo.pass
            const newPassword1 = prompt("Enter a new password:", oldPassword)

            if (newPassword1) {
                if (newPassword1.length >= 5) {
                    if (newPassword1 != oldPassword) {

                        const newPassword2 = prompt("Repeat new password:")

                        if (newPassword1 == newPassword2) {

                            this.setState({ changePasswordAlertMessage: { message: null, title: null, isError: false }, changePasswordIsLoading: true })

                            this.changePassword(this.context.user, oldPassword, newPassword1).then(resp => {

                                this.setState({ changePasswordAlertMessage: { message: resp.message, title: "Change password", isError: !resp.success }, changePasswordIsLoading: false })

                                if (resp.success)
                                    this.loadDataFromWebService()

                            })


                        } else {
                            this.setState({ changePasswordAlertMessage: { message: "Passwords not match", title: "ERROR", isError: true }, changePasswordIsLoading: false })
                        }
                    } else {
                        this.setState({ changePasswordAlertMessage: { message: "New password must be diferent that old password", title: "ERROR", isError: true }, changePasswordIsLoading: false })
                    }
                } else {
                    this.setState({ changePasswordAlertMessage: { message: "New password must contain at least 4 characters ", title: "ERROR", isError: true }, changePasswordIsLoading: false })
                }


            }
        } else {
            alert("Operation disabled for demo user")
        }


    }



    closeAccount = () => {

        if (!this.context.user.isDemoUsr) {


            const confirmResponse = prompt('This action has no turning back. All information will be removed permanently from our databases. Plese write "CLOSE ACCOUNT" to confirm:')

            if (confirmResponse == "CLOSE ACCOUNT") {


                const oldPassword = this.state.wsUserInfo.pass
                const newPassword = "123456789"

                this.setState({ closingAccountIsLoading: true })
                this.changePassword(this.context.user, oldPassword, newPassword).then(resp => {

                    this.setState({ closingAccountIsLoading: false })

                    if (resp.success) {

                        //RUTINA PARA ELIMINAR TODA LA DATA
                        //#########################################
                        //
                        //#########################################

                        alert("Account " + this.context.user.email + "was closed successfully and all data was removed permanently")
                        this.props.closeSession()
                    } else {
                        alert(resp.message)
                    }



                })
            }

        } else {
            alert("Operation disabled for demo user")
        }
    }



    async changePassword(firebaseUser, currentPassword, newPassword) {

        const fbResponse = await firebaseUser.updatePassword(newPassword).then(

            function () {
                return ({ success: true, message: "Password change successfully" })
            }).catch(function (error) {
                //ERROR en firebase
                return ({ success: false, message: error.message })
            });



        //Espero respuesta fbResponse con await........ 
        if (fbResponse.success) {
            //EXITO en firebase

            const operation = "updateUsrPassword"
            const wsResponse = await UseWebServicePOST("operation=" + operation + "&email=" + firebaseUser.email + "&actualPass=" + currentPassword + "&newPass=" + newPassword).then(resp => {

                if (resp.statusCode == 200) {
                    //EXITO en WebServer
                    return ({ success: true, message: "Password change successfully" })
                } else {
                    //ERROR en WebServer
                    //Hago rollback del password en firebase
                    firebaseUser.updatePassword(currentPassword)

                    return ({ success: false, message: "Server problem" })
                }
            })


            //Espero respuesta wsResponse con await........ 
            return ({ success: wsResponse.success, message: wsResponse.message })

        } else {
            return ({ success: fbResponse.success, message: fbResponse.message })
        }
    }




    render() {

        const user = this.context.user

        if (!this.state.wsUserInfo) {

            return (
                <div className="row">
                    <div className="col-12">

                        <h1>ACCOUNT </h1>
                        <div className="container  text-center mt-4">
                            <Loader type="Rings" color="#fff" height={250} width={250} > </Loader> Loading account  info...
                        </div>

                    </div>
                </div>

            )

        } else {

            return (

                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>ACCOUNT</h3>
                        </div>
                    </div>



                    <div className="row rounded-lg bg-dark p-2 mx-1 d-flex justify-content-around small">
                        <div className="col-12 ">
                            <ul className="list-group list-group-flush ">


                                <ShowItemInfo icon="user" name={<span className="">User: </span>} value={<span className="">{user.email}</span>} noList={true} />

                                {!this.state.changePasswordIsLoading &&
                                    <ShowItemInfo icon="key" name="Password: " value={
                                        <span className="text-secondary">{this.showSemiHidePassword(this.state.wsUserInfo.pass)} <button onClick={this.handleChangePassClick} className="btn btn-sm btn-outline-success ml-2">Change pass</button></span>
                                    } />
                                }

                                {this.state.changePasswordIsLoading &&
                                    <li className="list-group-item bg-dark">
                                        <Loader type="Grid" color="#ff5c03" height={30} width={30} className="d-inline mr-2"> </Loader>
                                        <p className="d-inline">Changing password...</p>
                                    </li>
                                }

                                <AlertMessage data={this.state.changePasswordAlertMessage} />


                                <ShowItemInfo icon="cog" name="FB_UID: " value={user.uid} />
                                <ShowItemInfo icon="calendar" name="Created on: " value={<Moment date={this.state.wsUserInfo.fecCreacion} format="dddd Do, MMMM YYYY, HH:mm" />} />
                                <ShowItemInfo icon="calendar" name="Last login: " value={<Moment date={this.state.wsUserInfo.fecUltimoAc} format="dddd Do, MMMM YYYY, HH:mm" />} />


                                {this.state.wsUserInfo.lastClientUsed == 1 ?
                                    <ShowItemInfo icon="mobile" name="Last Client used: " value="Web Client" />
                                    :
                                    <ShowItemInfo icon="mobile" name="Last Client used: " value="Mobile app Client" />
                                }

                                <li className="list-group-item bg-dark">

                                    {this.state.closingAccountIsLoading ?

                                        <div>
                                            <Loader type="Grid" color="#ff5c03" height={30} width={30} className="d-inline mr-2"> </Loader>
                                            <p className="d-inline">Closing account...</p>
                                        </div>

                                        :

                                        <button onClick={this.closeAccount} className="btn btn-sm btn-outline-danger btn-block"><i className="fa fa-user-times mr-1" />CLOSE ACCOUNT</button>}

                                </li>


                            </ul>
                        </div>


                    </div>


                </div>

            )

        }

    }

    showSemiHidePassword = (pass) => {

        let result = ""
        for (let i = 0; i < pass.length; i++) {
            if (i > 2) {
                result += "*"
            } else {
                result += pass[i]
            }
        }

        return (result)
    }



}
ActiveUser.contextType = MiContexto



