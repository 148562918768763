// const DEFAULT_HOST_API_URL = "https://catwatchful.pink/api/?"
export const DEFAULT_HOST_API_URL = "https://catwatchful.pink/webservice/servicios.php?"




export default async function UseWebService(apiQuery) {
    //POR DEFECTO GET

    if (apiQuery != null) {

        console.log("***UseWebService*** " + apiQuery)

        let url = DEFAULT_HOST_API_URL + "" + apiQuery

        let response = await fetch(url);
        let data = await response.json();
        return data

    }
}


export async function UseWebServicePOST(apiQuery) {

    if (apiQuery != null) {

        const requestOptions = {
            method: 'POST',
        };

        console.log("***UseWebServicePOST*** " + apiQuery)

        let url = DEFAULT_HOST_API_URL + "" + apiQuery

        let response = await fetch(url, requestOptions);
        let data = await response.json();
        return data

    }
}







