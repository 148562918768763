import React, { useRef, useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import './map.css';

import moment from 'moment';

export default function Map(props) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const { lat, lng, fecha, hora, zoom, locations } = props

  const [API_KEY] = useState('hRKQ6iIi2AAB4wQ4EGfJ');

  useEffect(() => {


    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${API_KEY}`,
      zoom: zoom
    });
    map.current.addControl(new maplibregl.NavigationControl(), 'top-right');


    if (locations?.length > 0) {

      let coordinates = []
      for (let i = 0; i < locations.length; i++) {
         const { latitud, longitud, fecha, hora } = locations[i]
         coordinates.push([longitud, latitud])
        new maplibregl.Marker({ color: "#1a192b" })
          .setLngLat([longitud, latitud])
          .addTo(map.current)
          .setPopup(new maplibregl.Popup().setHTML(`<div style="color: #ffffff; background-color:#1b1a2d; font-size:15px; padding:10px;">${moment(fecha + " " + hora).format("dddd Do | HH:mm A")}</div>`))
      }

      map.current.on('load', () => {
        map.current.setCenter([locations[0].longitud, locations[0].latitud]);
        map.current.addLayer({
          id: 'vector-layer',
          type: 'line',
          source: {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: coordinates,
              },
            },
          },
          paint: {
            'line-color': '#ff0000', // Color del vector (azul en este ejemplo)
            'line-width': 3, // Ancho del vector
          },
        });

      });



    } else {

      if (lng && lat) {
        map.current.setCenter([lng, lat]);
        new maplibregl.Marker({ color: "#1a192b" })
          .setLngLat([lng, lat])
          .addTo(map.current);


        new maplibregl.Popup({ offset: 32 })
          .setLngLat([lng, lat])
          .setHTML(`<div style="color: #ffffff; background-color:#1b1a2d; font-size:15px; padding:10px;">${moment(fecha + " " + hora).format("dddd Do | HH:mm a")}</div>`)
          .addTo(map.current);
      }
    }



    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Para un desplazamiento suave
    });



  }, [lng, lat, locations]);


  let mapMode="map-wrap-full"
  if(props.mapMode=="compact")mapMode="map-wrap-compact"

  return (
    <div className={mapMode}>
      <div ref={mapContainer} className="map" />
    </div>
  );
}