import { use } from 'i18next'
import './Buy.css'
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';


export default function Header(props) {

    const { t } = useTranslation();

    const language = props.language
    const [mainPage, setMainPage] = useState('https://catwatchful.com')


    useEffect(() => {
        if (language === 'es') setMainPage('https://catwatchful.es')
    }, [])


    const menuEs = [
        { label: "DEMO", url: "https://cp.catwatchful.es/?usr=ZGVtb0BkZW1vLmNvbXxkZW1vMTIz" },
        { label: "Descargar", url: "https://catwatchful.es/descargar" },
        { label: "Funciones", url: "https://catwatchful.es/funciones" },
        { label: "Modo De Uso", url: "https://catwatchful.es/modo-de-uso" },
        { label: "F.A.Q", url: "https://catwatchful.es/#FAQ" },
        { label: "Login", url: "https://cp.catwatchful.es" },
        // { label: "Contacto", url: "https://catwatchful.es/contacto" }
    ]

    const menuEn = [
        { label: "DEMO", url: "https://cp.catwatchful.com/?usr=ZGVtb0BkZW1vLmNvbXxkZW1vMTIz" },
        { label: "Download", url: "https://catwatchful.com/main/download" },
        { label: "Features", url: "https://catwatchful.com/main/features" },
        { label: "How To Use", url: "https://catwatchful.com/main/install-guide" },
        { label: "F.A.Q", url: "https://catwatchful.com/main/#FAQ" },
        { label: "Login", url: "https://cp.catwatchful.com" },
        // { label: "Contact", url: "https://catwatchful.com/main/contact" }
    ]

    const menu = language === 'es' ? menuEs : menuEn

    return (

        <div className='text-center py-2 small' style={{ backgroundColor: "#000" }}>
            <a href={mainPage} target='_blank'><img src="./images/logo-horizontal.png" className="img-responsive" height={"50px"} width="auto" alt="CatWatchful" /></a>

            <div className='d-flex justify-content-center pt-2 px-0'>
                {menu.map((item, index) => {
                    return (
                        <div>
                            {(index>0 && index <= menu.length - 1) && <span className='text-light mx-1'>|</span>}
                            <a className='text-light' href={item.url} target="_blank"><span className='ml-0'>{item.label}</span></a>
                        </div>
                    )
                })}
            </div>
        </div>
    )


}