import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'


export default function ResellerHeader(props) {
    const { t } = useTranslation();

    const [country, setCountry] = useState(null);

    const slideOptions = [
        { title: t('Reseller.slideTitleCamera'), color: "#RRR", ico: "fa fa-camera", image: 'images/baner1.jpg', text: t('Reseller.slideTextCamera') },
        { title: t('Reseller.slideTitleMicRec'), color: "#RRR", ico: "fa fa-microphone", image: 'images/baner1.jpg', text: t('Reseller.slideTextMicRec') },
        { title: t('Reseller.slideTitleRedes'), color: "#RRR", ico: "fa fa-whatsapp", image: 'images/baner1.jpg', text: t('Reseller.slideTextRedes') },
        { title: t('Reseller.slideTitleGps'), color: "#RRR", ico: "fa fa-map-marker", image: 'images/baner1.jpg', text: t('Reseller.slideTextGps') },
        { title: t('Reseller.slideTitleCallRec'), color: "#RRR", ico: "fa fa-phone", image: 'images/baner1.jpg', text: t('Reseller.slideTextCallRec') }
    ]

    const slideOutProperties = {
        duration: 5000,
        transitionDuration: 500,
        infinite: true,
        indicators: true,
        scale: 0.4,
        arrows: false
    };



    useEffect(() => {
        getCountry();
    }, []);

    const getCountry = async () => {
        const options = {
            method: 'GET',
            url: 'https://api.ipregistry.co/?key=8y2ujul6j3svpe4w',
        };
        try {
            const response = await axios.request(options);
            setCountry({ name: response.data.location.country.name, flag: response.data.location.country.flag.emojitwo });
        } catch (error) {
            console.error(error);
        }
    }


    const reseller = props.reseller
    console.log(reseller)
    return (
        <div className='container mb-4 '>

            <div>
                <h2 className='text-light'>{t('Reseller.firstTitle')}</h2>

                <div className='h5'>
                    {country ?
                        <div>{t('Reseller.appNumberOneCountry')} {country.name} <img src={country.flag} alt="flag" className='img-fluid' style={{ height: "50px" }} /></div>
                        :
                        <div>{t('Reseller.appNumberOne')}</div>
                    }

                </div>

                <Fade>
                    <div className='my-3'>
                        <div className="text-center my-2"> <a href='https://catwatchful.es' target="_blank"><img src="/images/googleplay.svg" alt="" width={"160px"} /></a></div>
                        {/* <a href='https://catwatchful.es/descargar' target="_blank"><button className='btn btn-outline-primary mr-1 btn-sm'><i className='fa fa-download mr-1' /> {t('Reseller.btnDownload')}</button></a> */}
                        <a href='https://cp.catwatchful.es/?usr=ZGVtb0BkZW1vLmNvbXxkZW1vMTIz' target="_blank"><button className='btn btn-outline-success btn-sm'><i className='fa fa-eye mr-1' />{t('Reseller.btnDemo')}</button></a>
                    </div>
                </Fade>


                <Bounce>
                    <div className='d-flex justify-content-center'>
                        <div className='col-12 col-md-8'>
                            <div className='h3'>
                                {t('Reseller.subtTitle3')}
                            </div>

                            <Slide {...slideOutProperties}>
                                {slideOptions.map((ch, index) => (
                                    <div className="pb-2 pt-1 rounded text-secondary"
                                        // style={{ backgroundColor: ch.color,height:"300px",backgroundSize: 'cover',backgroundImage: `url(${ch.image})` }
                                        style={{ backgroundColor: ch.color, backgroundSize: 'cover', height: "110px" }
                                        } key={index} >
                                        <div className="rounded mt-1 mb-0 text-center " >
                                            <span className="h4 text-uppercase" ><i className={ch.ico + " fa-1x"} /> {ch.title}</span>
                                        </div>
                                        <div className=" text-left mt-1 text-md-center" >{ch.text}</div>
                                    </div>
                                ))}
                            </Slide>


                        </div>
                    </div>
                </Bounce>


            </div>


            <div className='mt-1'>

                <Zoom>
                    <span className='mt-1 h2 bg-primary rounded text-uppercase px-2'>{t('Reseller.discountTitle')} </span>
                    <div className='h2 mb-0 mt-2 text-uppercase'>{t('Reseller.specialOffer')}</div>
                    <div className='h1 mb-0 colorTurquesa'>{reseller.nombres}</div>
                </Zoom>

                {reseller.avatar && <Flip><img src={reseller.avatar} className="rounded-circle" style={{ width: "120px", height: "120px" }} alt="Avatar" /></Flip>}

                <Fade left> <span className='text-success px-2 font-weight-bold'>{t('Reseller.aprovedReseller')} <i className='fa fa-check-circle text-success' /></span></Fade>


                {/* <div className='mt-1'>
                    <span> {reseller.empresa}</span>
                    <span> | {reseller.website}</span>
                </div> */}

            </div>



        </div>
    )
}