import React, { Component } from 'react'


import File from './File.jsx'
import { isImage } from './FMfunctions'


import { ButtonShowMore, ButtonGoUp } from '../../CommonComponents'

//Loaders
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


//reveal-effect
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';




const RENDER_SIZE_INCREMENT = 20

export default class Folder extends Component {

    constructor(props) {
        super(props)

        this.state = {

            myName: null,
            currentPath: null,

            folders: null,
            files: null,

            collapse: false,

            selectedFolder: null,
            selectedFile: null,


            renderSize: 50
        }

        this.cabezeraFolderTitle = React.createRef()
        this.fileInputRef = React.createRef()
    }


    handleFolderClick = (folder) => {
        if (folder?.name != this.state.selectedFolder?.name)
            this.setState({ selectedFolder: folder })
    }


    handleFileClick = (fileComponent) => {

        let file = fileComponent.state.file

        if (file?.name != this.state.selectedFile?.name) {
            this.setState({ selectedFile: file })
            this.props.updateCurrentFileComponent(fileComponent)
        } else {
            this.setState({ selectedFile: null })
        }
    }

    handleCollapseFolderClick = (value) => {
        this.setState({ collapse: value, })
    }



    componentDidMount() {


        const { name, path } = this.props

        let newPath = path
        if (name) {
            if (path != "/") {
                newPath = path + "/" + name
            } else {
                newPath = name
            }
        }

        let myNameAux = name
        if (!myNameAux)
            myNameAux = path


        this.setState({
            myName: myNameAux,
            currentPath: newPath
        },
            //me seteo como escucha 
            () => this.props.updateCurrentFolderComponent(this)
            //--------------------
        )


    }


    setFoldersAndFiles = (folders, files) => {

        this.setState({
            folders: folders,
            files: files
        },)// () => window.scrollTo({ behavior: 'smooth', top: this.cabezeraFolderTitle.current.offsetTop }))


    }


    setCurrentPath = (newCurrentPath) => {
        this.setState({
            currentPath: newCurrentPath,
            folders: null,
            files: null,
            myName: newCurrentPath,
        }, () => this.props.updateCurrentFolderComponent(this))

    }


    addNewFileUploaded = (uplFile) => {
        //uplFile viene en formato nativo, y debo convertirlo
        const ext = uplFile.name.split(".")[uplFile.name.split(".").length - 1].toLowerCase()
        let file = {
            name: uplFile.name,
            ext: ext,
            size: uplFile.size / 1024,
            isImage: isImage(ext)
        }
        let filesAux = this.state.files
        filesAux.push(file)
        this.setState({ files: filesAux })
    }


    fileExistsOnDownloadsHistory = (file) => {
        let downloadsHistory = this.props.downloadsHistory
        let completePath = this.state.currentPath + "/" + file.name
        return (downloadsHistory.map((h) => h.completePath).indexOf(completePath) != -1)
    }



    handleFileSelectToUpload = () => {
        const uplFile = this.fileInputRef.current.files[0]
        this.props.handleUploadFile(uplFile, this.state.currentPath)
    }


    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }



    render() {


        if (!this.state.collapse) {
            if (this.state.folders) {

                return (


                    <div className="bg-white text-dark rounded-lg">

                        <h1>{this.state.titulo}</h1>

                        {/* Folder seleced title */}
                        <div className="font-weight text-success" ref={this.cabezeraFolderTitle}>
                            <span onClick={() => this.handleCollapseFolderClick(true)}><i className="fa fa-folder-open-o fa-2x mr-1" />{this.state.myName} {this.state.files?.length > 0 && " (" + this.state.files.length + ")"}</span>
                            <span onClick={() => this.fileInputRef.current.click()}><i className="fa fa-cloud-upload ml-1" /> <span className="text-secondary small">upload file </span></span>

                            <input ref={this.fileInputRef} type="file" name="name" multiple={false} onChange={() => this.handleFileSelectToUpload()} style={{ display: 'none' }} />

                        </div>
                        {/* **** */}


                        <div className="ml-3">

                            {/* FOLDERS*********************** */}
                            {this.state.folders.map((fold, index) => {


                                return (
                                    <div key={index} >
                                        {this.state.selectedFolder?.name == fold.name ?
                                            <div onClick={() => this.handleFolderClick(fold)}>
                                                <Folder name={fold.name}
                                                    path={this.state.currentPath}
                                                    updateCurrentFolderComponent={this.props.updateCurrentFolderComponent}
                                                    updateCurrentFileComponent={this.props.updateCurrentFileComponent}
                                                    handleFileOperation={this.props.handleFileOperation}
                                                    handleUploadFile={this.props.handleUploadFile}
                                                    downloadsHistory={this.props.downloadsHistory}
                                                />
                                            </div>
                                            :
                                            <Bounce><div onClick={() => this.handleFolderClick(fold)}><i className="fa fa-folder-o fa-2x mr-1" />{fold.name} </div></Bounce>
                                        }
                                    </div>
                                )
                            })}


                            {/* FILES***************************** */}
                            {this.state.files.slice(0, this.state.renderSize).map((file, index) => {
                                return (
                                    <div key={index} >
                                        <Fade>
                                            <File
                                                file={file}
                                                path={this.state.currentPath}
                                                handleFileClick={this.handleFileClick}
                                                handleFileOperation={this.props.handleFileOperation}
                                                selected={this.state.selectedFile?.name == file.name}

                                                alreadyDownloaded={this.fileExistsOnDownloadsHistory(file)}
                                            />
                                        </Fade>
                                    </div>
                                )
                            })}
                            <ButtonGoUp arrayLength={this.state.files.length} maxLength={20}/>
                            <ButtonShowMore arrayLength={this.state.files.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />
                        </div>

                    </div>
                )
            } else {


                return (
                    <div className="d-flex">
                        <Loader type="TailSpin" color="#2443bf" height={30} width={30} > </Loader>
                        <div className="ml-1 mt-2">{this.state.myName}</div>
                    </div>

                )


            }

        } else {
            return <div onClick={() => this.handleCollapseFolderClick(false)}><i className="fa fa-folder-o fa-2x mr-1" />{this.state.myName}</div>
        }




    }


}
