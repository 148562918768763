import React, { Component } from 'react'
import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'
import { sendPushMessageToDevice } from '../CloudFunctions'



import { MyLoader,ButtonShowMore, ButtonShowAll, ShowFiltersResult, highlightFilter, ShowNoItemsYet, BuySubscriptionTag, ButtonGoUp, GetCurrentLetterFromArray, InputSearch, ShowFeatureNotSupported, ShowTotalAndLastUpdate } from '../CommonComponents'
import { resizeNavBar } from '../CommonFunctions'


//reveal-effect
import Bounce from 'react-reveal/Bounce';

import { withTranslation, useTranslation } from 'react-i18next';



const FEATURE_ID = 13

const RENDER_SIZE_INCREMENT = 20

class Contacts extends Component {


    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            //info de firebase database
            contacts: null,
            contactsFiltered: [],

            lastUpdate: null,

            filterContactByName: "",


            totalObjectsUnread: null,
            ts_lastView: null,

            renderSize: 50
        }
    }


    getOriginalDbRef = () => {
        //CREO el puntero
        return miFireBase.database().ref(this.context.dbPath).child("contactos")
    }



    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)



        this.dbRef = this.getOriginalDbRef()

        this.dbRef.on('value', snap => {
            console.log("****contactos dbRef.on VALUE****" + this.context.activeDevice.imei)

            let contactsAux = []
            snap.forEach((snap) => {
                if (snap.key != "ultVerificacion") {
                    contactsAux.push({
                        key: snap.key,
                        compareTo: snap.val().nombre.toLowerCase(),//Valor que usare para comparar y ordenar
                        nombre: snap.val().nombre,
                        numero: snap.val().numero,
                        favorito: snap.val().favorito,
                        id: snap.val().id
                    })
                } else {
                    this.setState({ lastUpdate: snap.val().replace('|', ' ') })
                }
            })

            this.sortArray(contactsAux)
            this.setState({
                contacts: contactsAux,
                contactsFiltered: contactsAux
            })

        })

        sendPushMessageToDevice(this.context.activeDevice.token, "getConntacts")//Para que envie actualizacion a firebase



    }


    componentWillUnmount() {
        this.dbRef.off()
    }




    sortArray = (targetArray) => {
        targetArray.sort((a, b) => {
            if (a.compareTo > b.compareTo) {
                return 1;
            }
            if (a.compareTo < b.compareTo) {
                return -1;
            }
            return 0;
        }
        );
    }


    handleSearchInputChange = (e) => {

        const newFilterContactByName = e.target.value

        let contactsFilteredAux = this.state.contacts.filter(function (con) {
            return con.nombre.toLowerCase().trim().includes(newFilterContactByName.toLowerCase().trim()) || con.numero.toLowerCase().trim().includes(newFilterContactByName.toLowerCase().trim())
        })

        this.setState({
            filterContactByName: newFilterContactByName,
            contactsFiltered: contactsFilteredAux
        })

    }


    handleShowAllClick = () => {
        let contactsAux = this.state.contacts//vuelvo a todos
        this.setState({
            contactsFiltered: contactsAux,
            filterContactByName: ""
        })
    }


    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }



    render() {

        const { t } = this.props; //función de traducción

        if (!this.state.contacts) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t('Contacts.title')}</h3>
                        <MyLoader text={t('Contacts.loading')}/>
                    </div>
                </div>
            )

        } else {

            if (this.state.contactsFiltered.length == 0) return <ShowNoItemsYet size={this.state.contacts.length} itemName="Contacts" />

            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t('Contacts.title')}</h3>
                        </div>
                    </div>


                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.contacts.length} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title={t('Contacts.contacts')} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ contacts: [], contactsFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />


                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-dark rounded-lg p-2">
                        <InputSearch value={this.state.filterContactByName} onChange={this.handleSearchInputChange} placeholder="Search Contact" />
                        <ButtonShowAll size1={this.state.contacts.length} size2={this.state.contactsFiltered.length} handleShowAllClick={this.handleShowAllClick} />
                    </div>


                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterContactByName} filterResultSize={this.state.contactsFiltered.length} filterName="contact" showAll={this.handleShowAllClick} />

                    <div className="row ">
                        <div className="col-12 ">

                            <ul className="list-group list-group-flush ">

                                {this.state.contactsFiltered.slice(0, this.state.renderSize).map((con, index) => {
                                    return (
                                        <div key={index}>
                                            <div>
                                                <GetCurrentLetterFromArray array={this.state.contactsFiltered} index={index} />
                                                <ShowContact
                                                    contacto={con}
                                                    filter={this.state.filterContactByName}
                                                    featureGranted={this.state.featureGranted} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </ul>
                            <ButtonGoUp arrayLength={this.state.contactsFiltered.length} maxLength={5} />
                            <ButtonShowMore arrayLength={this.state.contactsFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />
                        </div>
                    </div>



                </div>
            )

        }
    }

}
Contacts.contextType = MiContexto
Contacts = withTranslation()(Contacts);
export default Contacts;



function ShowContact(props) {

    let { contacto, filter, featureGranted } = props

    //------
    let nombre = contacto.nombre
    let numero = contacto.numero
    let favorito = contacto.favorito
    //------

    const getRandomBackground = () => {
        let colors = ["#00204a", "#005792", "#00bbf0", "#fdb44b", "#f58220", "#d9e021", "#8dc63f", "#39b54a"]
        let random = Math.floor(Math.random() * colors.length)
        return colors[random]
    }

    // if (featureGranted) {

    let blur = ""
    if (!featureGranted) blur = "divBlur"

    //SE MUESTRA
    return (
        <Bounce>
            <li className={"list-group-item bg-dark text-white pb-0 pt-1"}>

                <div className="row rounded">

                    <div className="col-2 col-md-1 text-center">

                        <span className="rounded-circle " style={{ width: "40px", height: "40px", backgroundColor: getRandomBackground(), display: "flex", alignItems: "center", justifyContent: "center" }} >
                            {/* <i className='fa fa-user' /> */}
                            <span className='font-weight-bold' style={{ fontSize: "23px" }}>{nombre?.substring(0, 1)}</span>
                        </span>
                    </div>


                    < div className={`col-10 col-md-11 ${blur}`}>
                        <div className="mb-0">{highlightFilter(nombre, filter)}{favorito == 1 && <i className="fa fa-star fa-1x ml-2 text-warning" />}</div>
                        <div className="text-info div"> {numero}</div>
                    </div>
                </div>

            </li>
        </Bounce>
    )

    // } 
    // else {

    //     //DEMO****
    //     return (
    //         <Bounce>

    //             <li className={"list-group-item bg-dark text-muted pb-0 pt-1"}>

    //                 <div className="row">

    //                     <div className="col-2 col-md-1 text-center">
    //                         <i className="fa fa-user fa-2x" />
    //                     </div>

    //                     < div className="col-10 col-md-11">
    //                         <h6 className="mb-0">{nombre?.substring(0, 2)}...{favorito == 1 && <i className="fa fa-star fa-1x ml-2" />}</h6>
    //                         <h6 > {numero?.substring(0, 3)}...  </h6><BuySubscriptionTag />
    //                     </div>

    //                 </div>

    //             </li>

    //         </Bounce>
    //     )
    // }


}