import React, { Component } from 'react'
import { Link } from "react-router-dom";

import miFireBase from '../../config/config'
import { AlertMessage } from '../../CommonComponents'

import Fade from 'react-reveal/Fade';


import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import { withTranslation, useTranslation } from 'react-i18next';


class ForgotPassword extends Component {

    constructor(props) {
        super(props)

        this.state = ({
            alertMessage: { message: "", title: "", isError: false },
            isLoading: false

        })

        this.emailRef = React.createRef()

    }


    handleSubmit = async (evt) => {
        const { t } = this.props; //función de traducción
        evt.preventDefault()

        let currentComponent = this;

        let email = this.emailRef.current.value

        if (email) {
            this.setState({ isLoading: true, alertMessage: { message: null } })
        
            await miFireBase.auth().sendPasswordResetEmail(email).then(
                function () {
                    currentComponent.setState({ alertMessage: { message: t('ForgotPass.emailSent'), title: t('ForgotPass.passResetOk'), isError: false, faIcon: "check" }, isLoading: false })
                    currentComponent.emailRef.current.value = ""
                }

            ).catch(
                function (error) {
                    console.log(error)
                    currentComponent.setState({ alertMessage: { message: error.message, title: t('ForgotPass.errorHappen'), isError: true }, isLoading: false })
                }
            );

        } else {
            this.setState({ alertMessage: { message: t('ForgotPass.putEmail'), title: "Error", isError: true } })
        }


    }

    render() {
        const { t } = this.props; //función de traducción

        return (
            <div className="col-12 col-md-6 mb-4">

                <Fade>
                    <form onSubmit={this.handleSubmit} className="bg-dark blueShadow  py-2 px-4" style={{ border: "1px solid #2d2d2d", borderRadius: "15px" }} >
                        <h5 className='text-uppercase text-center my-2 mb-3'>{t('ForgotPass.title')}</h5>


                        <div className="input-group form-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" ><i className="fas fa-user"></i></span>
                            </div>
                            <input type="email" className="form-control" ref={this.emailRef} placeholder={t('ForgotPass.putEmail')} autoComplete="username" required="required" />
                        </div>

                        {!this.state.isLoading && <div className="text-center ">
                            <Link to="/"><button className="btn btn-outline-primary  mx-2" style={{ width: "130px" }}>{t('ForgotPass.back')}</button></Link>
                            {!this.state.isLoading &&
                                <button type="submit" className="btn btn-primary  my-2 " style={{ width: "130px" }}>{t('ForgotPass.submit')}</button>
                            }
                        </div>}
                        {this.state.isLoading && <div className="text-center my-2 text-secondary" style={{fontSize:"0.9em"}} ><Loader type="Puff" color="#2274cd" height={60} width={60} ></Loader>{t('ForgotPass.loading')} </div>}

                    </form>
                </Fade>

                <AlertMessage data={this.state.alertMessage} />



            </div>
        )
    }



}

ForgotPassword = withTranslation()(ForgotPassword);
export default ForgotPassword;

