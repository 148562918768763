const CLOUD_FUNCTIONS_URL = "https://us-central1-catwatchful-e03b8.cloudfunctions.net/"



export async function sendPushMessageToDevice(token, text) {
    console.log("#### sendPushMessageToDevice ###  " + text)

    let cloudFunctionName = "sendFCM"
    let url = `${CLOUD_FUNCTIONS_URL}${cloudFunctionName}`

    const fcmResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "token": token,
            "text": text
        })
    });
    const content = await fcmResponse.json();
    //console.log(content);
    return content;

}



export async function functionDeleteDevice(fbuid, imei) {

    if (fbuid && imei) {
        console.log("***functionDeleteDevice*** ")

        let cloudFunctionName = "deleteDevice"
        let url = `${CLOUD_FUNCTIONS_URL}${cloudFunctionName}?fbuid=${fbuid}&imei=${imei}`
        //let url = 'https://cors-anywhere.herokuapp.com/'+`${CLOUD_FUNCTIONS_URL}${cloudFunctionName}?fbuid=${fbuid}&imei=${imei}`;

        let data = null
        try {
            const response = await fetch(url)
            data = await response.json();
        } catch (error) {
            data = ({ function: "deleteDevice", code: 207, message: error })
        }

        return data
    }
}


export async function functionDeleteFeatureData(fbuid, imei, featureId) {

    if (fbuid && imei && featureId) {
        console.log("***functionDeleteFeatureData*** ")


        let cloudFunctionName = "deleteFeatureData"
        let url = `${CLOUD_FUNCTIONS_URL}${cloudFunctionName}?fbuid=${fbuid}&imei=${imei}&featureId=${featureId}`

        let data = null
        try {
            const response = await fetch(url)
            data = await response.json();
        } catch (error) {
            data = ({ function: "deleteFeatureData", code: 207, message: error })
        }
        return data
    }
}




export async function functionGetRandomRealTimeData(fbuid, imei, dataType) {

    if (fbuid && imei && dataType) {
        await new Promise(r => setTimeout(r, 2000));//Para que no sea instantaneo

        console.log("***functionGetRandomRealTimeData*** ")

        let cloudFunctionName = "getRandomRealTimeData"
        let url = `${CLOUD_FUNCTIONS_URL}${cloudFunctionName}?fbuid=${fbuid}&imei=${imei}&dataType=${dataType}`

        let data = null
        try {
            const response = await fetch(url)
            data = await response.json();
        } catch (error) {
            data = ({ function: "getRandomRealTimeData", code: 207, message: error })
        }

        return data
    }
}









