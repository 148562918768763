import React, { Component, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import './Dashboard.css'

import MiContexto from '../MiContexto'
import miFireBase from '../config/config'

import { ShowItemDate } from '../CommonComponents'
import { resizeNavBar, openUrl, getRandomNumber } from '../CommonFunctions'

import DeviceInfo from './DeviceInfo'
import AmountsByPeriod from './AmountsByPeriod'
import TimeLine from './TimeLine'



//reveal-effect
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';

// //Mapa GPS con GoogleMaps
import GoogleMaps from "simple-react-google-maps"


//Maptiler
import Map from './Map.jsx';

import { withTranslation, useTranslation } from 'react-i18next';


const FEATURE_ID = 25



class Dashboard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,
            lastLocation: null,
        }
    }




    async componentDidMount() {
        resizeNavBar()
        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        this.getLastLocation()
    }




    getLastLocation = () => {
        miFireBase.database().ref().child("usuarios").child(this.context.user.uid).child("dispositivos").child(this.context.activeDevice.imei).child("coordenadas").limitToLast(1).once('value', snap => {
            snap.forEach((snap) => { this.setState({ lastLocation: snap.val() }) })
        })
    }


    render() {
        const { t } = this.props; //función de traducción

        return (
            <div onClick={() => resizeNavBar()}>

                <div className="mt-0">
                    <DeviceInfo compactMode={true} />
                </div>

                {this.state.lastLocation &&
                    < div className="mb-4 rounded-top border-top border-secondary " >
                        <div className="text-secondary  mt-1 text-uppercase w-100 py-2" style={{ borderRadius: "10px", fontSize: "1.2em" }}><i className="fa fa-map-marker mr-1" />{t('Dashboard.lastLocation')}</div>
                        <ShowLastLocation mapProvider={1} location={this.state.lastLocation} featureGranted={this.state.featureGranted} />
                    </div>
                }

                <div className="mb-4 rounded-top border-top border-secondary ">
                    <div className="text-secondary   mt-1 text-uppercase w-100 py-2" style={{ borderRadius: "10px", fontSize: "1.2em" }}><i className="fa fa-database mr-1" />{t('Dashboard.datosDeHoy')}</div>
                    <AmountsByPeriod compactMode={true} />
                </div>


                <div className="mb-4 rounded-top border-top border-secondary ">
                    <div className="text-secondary   mt-1 text-uppercase w-100 py-2" style={{ borderRadius: "10px", fontSize: "1.2em" }}><i className="fa fa-line-chart mr-1" />{t('Dashboard.timeLine')}</div>
                    <TimeLine compactMode={true} recentFirst={true} />
                </div>

            </div >
        )
    }



}
Dashboard.contextType = MiContexto
Dashboard = withTranslation()(Dashboard);
export default withRouter(Dashboard);






function ShowLastLocation(props) {

    //mapProvider == 1 MAPBOX
    //mapProvider == 2 GOOGLE MAPS

    const { mapProvider, location, featureGranted } = props

    if (location) {

        let latitud = location.latitud
        let longitud = location.longitud

        let fecha = location.fecha
        let hora = location.hora

        let blur = ""
        if (!featureGranted) blur = "divBlur"


        if (mapProvider == 1) {
            //MAPTILER
            return (
                <Bounce>
                    <div className=" rounded-lg">
                        {/* <span className="text-info mt-2"><span className="mr-2">Lat: {latitud} </span>Long: {longitud}</span> */}
                        {/* <a href={"https://www.google.com/maps?q=" + latitud + "," + longitud} target="_blank" className="btn btn-outline-info btn-sm ml-2">View on Google Maps </a> */}
                    </div>
                    <div className={`bg-danger p-0 mt-1 d-flex justify-content-center ${blur}`}>
                        <Map lat={latitud} lng={longitud} zoom={16} fecha={fecha} hora={hora} oneUpdate={true} mapMode="compact" />
                    </div>
                </Bounce>
            )


        } else if (mapProvider == 2) {
            //GOOGLE MAPS
            return (
                <Bounce>
                    <div className=" rounded-lg">
                        <ShowItemDate fecha={fecha} hora={hora} />
                        <a href={"https://www.google.com/maps?q=" + latitud + "," + longitud} target="_blank" className="btn btn-outline-info btn-sm ml-2">View on Google Maps </a>
                    </div>

                    <div className="p-0 mt-1 d-flex justify-content-center">

                        <GoogleMaps
                            apiKey={"AIzaSyC_6gYSYaFPnbTcJ21R8hhspKYahwg2vR4"}
                            style={{ height: "401px", width: "100%" }}
                            zoom={16}
                            center={{
                                lat: latitud,
                                lng: longitud
                            }}
                            markers={[
                                { lat: latitud, lng: longitud },
                                // { lat: 40.412072, lng: -3.676463 },
                            ]}
                        />
                    </div>

                </Bounce>
            )

        }


    } else {
        return null
    }
}








