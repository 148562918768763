import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';



export function Logos(props) {
    return (
        <React.Fragment>
            <div className='d-flex justify-content-center'>
                <img className='mb-1' src="./images/pci.svg" style={{ width: "auto", height: "25px" }} alt="pci" />
                <img className='mb-1 mx-3' src="./images/stopbulling.svg" style={{ width: "auto", height: "25px" }} alt="pci" />
                <img className='mb-1' src="./images/creditsafe.svg" style={{ width: "auto", height: "25px" }} alt="pci" />
            </div>
        </React.Fragment>
    )
}

export function SecurePayment(props) {
    const { t } = useTranslation();
    return (
        <div className='d-flex mt-4 mb-4  rounded p-1'>

            <div>
                <div className='text-secondary mb-1 small'>{t('Buy.securePayment')}</div>
                <img className='mb-1' src="./images/metodosPago2.png" style={{ width: "auto", height: "25px" }} alt="PayPal Acceptance Mark" />
            </div>

            <div className='ml-4'>
                <div className='text-secondary mb-1  small'>{t('Buy.otherPayments')}</div>
                <img className='mb-1' src="./images/western.png" style={{ width: "auto", height: "25px" }} alt="Westernunion" />
            </div>

        </div>
    )
}


export function Terms(props) {
    const { t } = useTranslation();

    const [terms, setTerms] = useState(true);



    return (
        <React.Fragment>
            <div className="form-check mt-3">
                <input type="checkbox" className="form-check-input" id="Check1" checked={terms} onChange={() => setTerms(!terms)} />
                <label className="form-check-label cheked small text-secondary" htmlFor="Check1" >{t('Buy.termsAgree')}</label>
            </div>
            <div className='d-flex justify-content-center mt-1 text-secondary'>
                <div className='' style={{ width: "80px" }} >
                    <i className='fa fa-handshake fa-1x' />
                    <div className='small' ><a className='text-secondary' href="https://catwatchful.com/legal/#terminos" target='_blank'><u>{t('Buy.termsOfUse')}</u></a></div>
                </div>
                <div className='' style={{ width: "80px" }}>
                    <i className='fa fa-unlock-alt fa-1x' />
                    <div className='small'><a className='text-secondary' href="https://catwatchful.com/legal/#privacidad" target='_blank'><u>{t('Buy.privacyPolicy')}</u></a></div>
                </div>
                <div className='' style={{ width: "80px" }} >
                    <i className='fa-solid fa-money-check-dollar fa-1x' />
                    <div className='small' ><a className='text-secondary' href="https://catwatchful.com/legal/#reembolso" target='_blank'><u>{t('Buy.refundPolicy')}</u></a></div>
                </div>

            </div>
        </React.Fragment>
    )
}


export function Acordion(props) {
    const { t } = useTranslation();

    const title = t('Buy.pricesFaq')
    const elements = [
        {
            id: 1,
            title: t('Buy.pricesFaqtitle1'),
            content: <div>{t('Buy.pricesFaqMsj1')}</div>
        },
        {
            id: 2,
            title: t('Buy.pricesFaqtitle2'),
            content: <div>{t('Buy.pricesFaqMsj2')} </div>
        },
        {
            id: 3,
            title: t('Buy.pricesFaqtitle3'),
            content: <div>{t('Buy.pricesFaqMsj3')}</div>
        },
        {
            id: 4,
            title: t('Buy.pricesFaqtitle4'),
            content: <div>{t('Buy.pricesFaqMsj4')}</div>
        },
        {
            id: 5,
            title: t('Buy.pricesFaqtitle5'),
            content: <div>{t('Buy.pricesFaqMsj5')}</div>
        },
        {
            id: 6,
            title: t('Buy.pricesFaqtitle6'),
            content: <div>{t('Buy.pricesFaqMsj6')}</div>
        },
        {
            id: 7,
            title: t('Buy.pricesFaqtitle7'),
            content: <div>{t('Buy.pricesFaqMsj7')}</div>
        },
        {
            id: 8,
            title: t('Buy.pricesFaqtitle8'),
            content: <div>{t('Buy.pricesFaqMsj8')}</div>
        },
    ]

    return (
        <React.Fragment>

            <div className='mb-2 h5 text-left mt-4 text-center '>{title}</div>

            <div className='d-flex justify-content-center'>
                <div className="accordion" style={{ maxWidth: "550px" }} id="accordionExample" >
                    {elements.map(elem => {
                        return (
                            <div className="card  bg-dark " key={elem.id} >
                                <div className="card-header" id={"heading" + elem.id}>
                                    <div className="mb-0">
                                        <div className="btn btn-link btn-block text-left px-0" type="button" data-toggle="collapse" data-target={"#collapse" + elem.id} aria-expanded="true" aria-controls={"collapse" + elem.id}>
                                            <i className="fa fa-angle-right fa-1x mr-1 " /><span className=' text-secondary' style={{ fontSize: "0.8em" }}>{elem.title}</span>
                                        </div>
                                    </div>
                                </div>
                                <div id={"collapse" + elem.id} className="collapse" aria-labelledby={"heading" + elem.id} data-parent="#accordionExample" >
                                    <div className="card-body blackGradient bg-dark text-left" style={{ fontSize: "0.8em" }}>
                                        {elem.content}
                                    </div>
                                </div>
                            </div>

                        )
                    })}
                </div>

            </div>





        </React.Fragment >
    )

}


export function Benefits(props) {
    const { t } = useTranslation();

    const title = t('Buy.benefits')
    const elements = [
        {
            id: 1,
            title: t('Buy.benefitsTitle1'),
            content: <div>{t('Buy.benefitsMsj1')}</div>,
            icon: "check-circle"
        },
        {
            id: 2,
            title: t('Buy.benefitsTitle2'),
            content: <div>{t('Buy.benefitsMsj2')}</div>,
            icon: "eye"
        },
        {
            id: 3,
            title: t('Buy.benefitsTitle3'),
            content: <div>{t('Buy.benefitsMsj3')}</div>,
            icon: "undo"
        }, {
            id: 4,
            title: t('Buy.benefitsTitle4'),
            content: <div>{t('Buy.benefitsMsj4')}</div>,
            icon: "question-circle"
        }]

    return (
        <React.Fragment >
            <div className='mb-0 h5 text-center mt-4 mb-2'>{title}</div>


            <div >
                {elements.map(elem => {
                    return (
                        <div className='d-flex mb-4  justify-content-center text-secondary' style={{ alignItems: "center" }} key={elem.id}>

                            <div className=' p-2 rounded-right blackGradient ' style={{ maxWidth: "550px" }}>

                                <div>
                                    <div className='text-left font-weight-bold mb-2 text-uppercase px-3'><i className={`fa fa-${elem.icon} fa-2x  mr-2 mt-1 text-primary`} />{elem.title}</div>
                                    <div className='text-left small px-2'> {elem.content}</div>
                                </div>

                            </div>

                        </div>


                    )
                })}
            </div>

        </React.Fragment >
    )
}


export function Footer(props) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <hr style={{ backgroundColor: "#5a5a5a" }} />

            <div className='text-secondary' style={{ fontSize: "0.9em" }}>
                <div className=''>{t('Buy.copyright', { year: (new Date()).getFullYear() })}  </div>
                <div className=''> {t('Buy.trademarks')}</div>
            </div>

            <div className='text-secondary text-left mt-4' style={{ fontSize: "0.7em", lineHeight: "13px" }}>
                {t('Buy.disclaimer')}
            </div>
        </React.Fragment>
    )
}