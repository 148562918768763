import React, { useState, useEffect } from "react";

import miFireBase from './config/config'
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

import { useTranslation } from 'react-i18next'

import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import { set } from "lodash";




export function IA_Component(props) {
    const { t } = useTranslation() //función de traducción
    const { prompt, featureGranted } = props

    const [isLoading, setIsLoading] = useState(false)
    const [iaResponse, setIaResponse] = useState(null)

    useEffect(() => {
        console.log("IA_Component")
        setIaResponse(null)
        setIsLoading(false)

        if (document.getElementById("collapseIa").classList.contains("show"))
            document.getElementById("collapseIa").classList.toggle("show")
    }, [prompt])



    const IA_call = async () => {

        if (!prompt) {
            console.log("Prompt vacío")
            return "Prompt vacío"
        }

        let text = null

        if (!isLoading && !iaResponse) {
            if (featureGranted) {
                const vertexAI = getVertexAI(miFireBase);
                const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });


                setIaResponse(null)
                setIsLoading(true)
                console.log("IA START")
                let result = await model.generateContent(prompt);
                setIsLoading(false)
                console.log("IA END")
                // result = await model.generateContent(`Darle un lindo y presentable formato HTML con colores e iconos faicon, 
                //     No incluir explicacion del codigo. El fondo debe ser negro. El estilo no debe incluir la etiqueta body ni container: ${result.response.text()}.`);
                text = result.response.text()
            } else {
                text = `<h4>${t("IA.suscripcion")}</h4>`
            }
        }


        setIaResponse(text)


        if (!document.getElementById("collapseIa").classList.contains("show"))
            document.getElementById("collapseIa").classList.toggle("show")


        console.log(text);
    }


    const convertToNiceHtml = (text) => {
        if (!text) return null
        //text = text.replace(/\n/g, "<br>")
        text = text.replace(/\*/g, '•');

        // Reemplazar encabezados
        text = text.replace(/## (.*?)\n/g, '<h4 class="text-uppercase mt-2 pb-0">$1</h4>');
        text = text.replace(/• ••(.*?):••/g, '<h6 class="text-success mt-1 pb-0">$1:</h6>');
        text = text.replace(/••(.*?):••/g, '<h4 class="text-warning mt-2 text-uppercase pb-0">$1:</h4>');
        text = text.replace(/••(.*?).•/g, '<span class="text-info">$1</span>');
        text = text.replace(/\• /g, '<br>');
        //text = text.replace(/• (.*?)./g, '<b>$1</b>');
        return (
            <div className=''>
                <hr className='bg-secondary' />

                <Fade>
                    <div className=' mx-2 text-light' dangerouslySetInnerHTML={{ __html: text }} />
                </Fade>
                {/* // <iframe width={'100%'} height={'500px'} srcDoc={text} />  */}
                {/* <iframe srcdoc={`<iframe srcdoc='${result}'></iframe>">`} /> */}
                <hr className='bg-secondary' />
            </div>
        )
    }

    return (
        <div className="text-center py-0">

            {!isLoading ?
                <div className='text-right btn-sm my-0 p-0' >
                    {!iaResponse ?
                        <button className="btn btn-danger py-0 mt-1" onClick={() => IA_call()}>{t("IA.btnAnalizar")}<i className='fa fa-magic ml-1' /></button>
                        :
                        <button className="btn btn-success py-0 mt-1" data-toggle="collapse" data-target="#collapseIa" aria-expanded="false" aria-controls="collapseIa" >{t("IA.analisisIa")} <i className='fa fa-chevron-down ml-1' /></button>
                    }
                </div>
                :
                <div className='text-right btn-sm  my-0 p-0'>
                    <button className="btn btn-danger py-0" disabled ><i className='fa fa-spinner fa-spin' /> {t("IA.analizando")} </button>
                </div>
            }


            <div className="text-left">
                <div className="collapse pb-4 py-0" id="collapseIa">
                    {iaResponse && <div>
                        {convertToNiceHtml(iaResponse)}
                        <div className='text-center' data-toggle="collapse" data-target="#collapseIa" aria-expanded="false" aria-controls="collapseIa" onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 })}>
                            <i className="fa fa-chevron-circle-up text-success fa-2x" />
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )

}
