import React, { Component } from 'react'

//Para recibir datos como parametros via URL
import { withRouter } from "react-router";
//---

import MiContexto from '../MiContexto'
import UseWebService, { UseWebServicePOST } from '../UseWebService'
import miFireBase from '../config/config'


import {MyLoader, ShowFiltersResult, ShowNoItemsYet, ShowItemDate, BuySubscriptionTag, GetCurrentDateFromArray, ButtonGoUp, ButtonShowMore, ButtonShowAll, ButtonOrderFirst, ShowFeatureNotSupported, ShowTotalAndLastUpdate, SelectDate } from '../CommonComponents'
import { resizeNavBar, sortArrayByFechaHora, sortArrayByRecentFirst, getDateFilterFromUrl } from '../CommonFunctions'


//reveal-effect
import Zoom from 'react-reveal/Zoom';

//Calculos con fechas
import moment from 'moment';
import { format } from "date-fns";

import { withTranslation, useTranslation } from 'react-i18next';

import { IA_Component } from '../IA'


const FEATURE_ID = 16
const LIMIT_TO_LAST = 500

const RENDER_SIZE_INCREMENT = 10

class WifiHistory extends Component {

    constructor(props) {
        super(props)

        this.state = {
            featureGranted: false,

            wifiHistory: null,
            wifiHistoryFiltered: [],
            wifiHistoryOrderRecentFirst: true,

            groupWifiName: null,//Agrupacion de wifis por nombre de red Wifi
            groupWifiConnectionStatus: null,//Agrupacion de wifis por estado de conexion

            filterDate: null,
            filterWifiName: -1,
            filterWifiConnectionStatus: -1,

            lastUpdate: null,
            totalObjects: null,

            totalObjectsUnread: null,
            ts_lastView: null,

            renderSize: 50
        }

    }

    getOriginalDbRef = () => {
        //CREO el puntero 

        if (this.context.activeDevice.statusCode != 1)//DUMMY PATH
            return miFireBase.database().ref(this.context.dbPathDummy).child("connectionHistory")

        return miFireBase.database().ref(this.context.dbPath).child("connectionHistory")
    }


    async componentDidMount() {
        resizeNavBar()

        let featureGranted = (this.context.activeDevice.statusCode == 1) && this.context.activeDevice.subscription.features[FEATURE_ID]
        this.setState({ featureGranted: featureGranted })

        await UseWebService("operation=getNewDataNotify&featureId=" + FEATURE_ID + "&imei=" + this.context.activeDevice.imei).then(resp => {
            if (resp.statusCode === 200) this.setState({ totalObjectsUnread: resp.data[0].newItems, ts_lastView: resp.data[0].ts_lastView })
        })

        if (featureGranted)//Marco como leidos solamente si se pueden ver
            UseWebServicePOST("operation=setNotifyView" + "&imei=" + this.context.activeDevice.imei + "&featureId=" + FEATURE_ID)


        const dbRef = this.getOriginalDbRef().orderByChild('fecha').limitToLast(LIMIT_TO_LAST)//Por defecto son los primeros 500 ordenados por fecha

        dbRef.once('value', snap => {
            console.log("****WifiHistory dbRef.once VALUE****" + this.context.activeDevice.imei)

            let wifiHistoryAux = []

            let groupWifiNameAux = []
            let groupWifiConnectionStatusAux = []

            snap.forEach((snap) => {

                const wifi = ({
                    key: snap.key,

                    bssid: snap.val().bssid,
                    connectionStatus: this.getConectionStatusName(snap.val().connectionStatus),
                    statusCode: snap.val().connectionStatus,
                    linkSpeed: snap.val().linkSpeed,
                    mac: snap.val().mac,
                    publicIp: snap.val().publicIp,
                    rssiLevel: snap.val().rssiLevel,
                    wifiIp: snap.val().wifiIp,
                    wifiName: snap.val().wifiName,

                    fecha: snap.val().fecha,
                    hora: snap.val().hora,
                    unRead: (moment(snap.val().fecha + " " + snap.val().hora).format("X") > this.state.ts_lastView) && this.state.totalObjectsUnread > 0
                })



                wifiHistoryAux.push(wifi)//No se debe verificar si ya existe, porque SOLO se van mandando las nuevas(no el historial del telefono)
                this.addToGroupByWifyName(wifi, groupWifiNameAux)
                this.addToGroupByWifyConectionStatus(wifi, groupWifiConnectionStatusAux)


            })
            sortArrayByFechaHora(wifiHistoryAux)//solo se hace al principio
            sortArrayByFechaHora(groupWifiNameAux)
            sortArrayByFechaHora(groupWifiConnectionStatusAux)



            //ultimo elemento recibido
            let lastUpdate = null
            let lastObject = wifiHistoryAux[wifiHistoryAux.length - 1]
            if (lastObject) lastUpdate = lastObject.fecha + " " + lastObject.hora
            //---------


            sortArrayByRecentFirst(wifiHistoryAux, this.state.wifiHistoryOrderRecentFirst)

            this.setState({
                wifiHistory: wifiHistoryAux,
                wifiHistoryFiltered: wifiHistoryAux,

                groupWifiName: groupWifiNameAux.reverse(),
                groupWifiConnectionStatus: groupWifiConnectionStatusAux.reverse(),

                lastUpdate: lastUpdate,
                totalObjects: wifiHistoryAux.length,
            })

        }).then(() => {
            const dateFilterFromUrl = getDateFilterFromUrl(this.props.location.search)
            if (dateFilterFromUrl)
                this.handleCalendarChange(dateFilterFromUrl)
        })


    }


    getConectionStatusName = (statusCode) => {
        const { t } = this.props; //función de traducción
        if (statusCode == -2) return t('WifiHistory.powerOff')
        if (statusCode == -1) return t('WifiHistory.powerOn')
        if (statusCode == 0) return t('WifiHistory.noInternet')
        if (statusCode == 1) return t('WifiHistory.mobileData')
        if (statusCode == 2) return t('WifiHistory.wifi')
    }



    addToGroupByWifyName = (wifi, currentGroup) => {

        if (wifi.wifiName && !wifi.wifiName.includes("unknown")) {

            //selecciono la posicion del elemento actual (si es que existe, sino pos=-1)
            let pos = currentGroup.map((s) => s.wifiName).indexOf(wifi.wifiName)

            if (pos < 0) {
                //Creo un nuevo elemento en group
                currentGroup.push({
                    wifiName: wifi.wifiName,
                    cant: 1,
                    fecha: wifi.fecha,
                    hora: wifi.hora,
                })
            } else {
                //Actualizo el existente
                currentGroup[pos].cant += 1
                currentGroup[pos].fecha = wifi.fecha
                currentGroup[pos].hora = wifi.hora
            }
            //----------------------------------
        }
    }

    addToGroupByWifyConectionStatus = (wifi, currentGroup) => {

        //selecciono la posicion del elemento actual (si es que existe, sino pos=-1)
        let pos = currentGroup.map((s) => s.connectionStatus).indexOf(wifi.connectionStatus)

        if (pos < 0) {
            //Creo un nuevo elemento en group
            currentGroup.push({
                connectionStatus: wifi.connectionStatus,
                cant: 1,
                fecha: wifi.fecha,
                hora: wifi.hora,
            })
        } else {
            //Actualizo el existente
            currentGroup[pos].cant += 1
            currentGroup[pos].fecha = wifi.fecha
            currentGroup[pos].hora = wifi.hora
        }
        //----------------------------------

    }







    deleteObject = (objKey) => {
        if (!this.context.user.isDemoUsr) {
            this.getOriginalDbRef().child(objKey).remove()//elimino de firebase

            const originalArray = this.state.wifiHistory
            const filteredArray = this.state.wifiHistoryFiltered

            if (originalArray) {
                originalArray.splice(originalArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array principal
                if (originalArray != filteredArray)
                    filteredArray.splice(filteredArray.map((c) => c.key).indexOf(objKey), 1)//elimino del array filtrado

                //Actualizo estados
                this.setState({
                    wifiHistory: originalArray,
                    wifiHistoryFiltered: filteredArray,
                    totalObjects: this.state.wifiHistory.length
                })
            }
        }
    }


    handleShowMoreClick = () => {
        this.setState({ renderSize: this.state.renderSize + RENDER_SIZE_INCREMENT })
    }

    handleCalendarChange = (date) => {
        if (date) {

            let wifiHistoryFilteredAux = []

            this.state.wifiHistory.map(wifi => {
                if (wifi.fecha == format(date, 'yyyy-MM-dd'))
                    wifiHistoryFilteredAux.push(wifi)
            })

            sortArrayByRecentFirst(wifiHistoryFilteredAux, this.state.wifiHistoryOrderRecentFirst)

            this.setState({
                filterDate: date,
                filterWifiConnectionStatus: -1,
                filterWifiName: -1,
                wifiHistoryFiltered: wifiHistoryFilteredAux
            })

        } else {
            this.handleShowAllClick()//vuelvo a todos
        }

    }


    handleWifiNameChange = (event) => {

        const selectedWifiGroup = event.target.value

        if (selectedWifiGroup != -1) {

            let wifiHistoryFilteredAux = []
            this.state.wifiHistory.map(wifi => {
                if (wifi.wifiName == selectedWifiGroup)
                    wifiHistoryFilteredAux.push(wifi)
            })


            sortArrayByRecentFirst(wifiHistoryFilteredAux, this.state.wifiHistoryOrderRecentFirst)

            this.setState({
                filterWifiName: selectedWifiGroup,
                filterDate: null,
                filterWifiConnectionStatus: -1,
                wifiHistoryFiltered: wifiHistoryFilteredAux
            })


        } else {
            this.handleShowAllClick()

        }
    }



    handleConnectionStatusChange = (event) => {

        const selectedConnectionStatus = event.target.value

        if (selectedConnectionStatus != -1) {

            let wifiHistoryFilteredAux = []
            this.state.wifiHistory.map(wifi => {
                if (wifi.connectionStatus == selectedConnectionStatus)
                    wifiHistoryFilteredAux.push(wifi)
            })
            sortArrayByRecentFirst(wifiHistoryFilteredAux, this.state.wifiHistoryOrderRecentFirst)

            this.setState({
                filterWifiConnectionStatus: selectedConnectionStatus,
                filterDate: null,
                filterWifiName: -1,
                wifiHistoryFiltered: wifiHistoryFilteredAux
            })


        } else {
            this.handleShowAllClick()//vuelvo a todos
        }

    }




    handleOrderChange = () => {
        this.setState({
            wifiHistoryFiltered: this.state.wifiHistoryFiltered.reverse(),
            wifiHistoryOrderRecentFirst: !this.state.wifiHistoryOrderRecentFirst
        })
    }


    handleShowAllClick = () => {

        let wifiHistoryAux = this.state.wifiHistory//vuelvo a todos
        sortArrayByRecentFirst(wifiHistoryAux, this.state.wifiHistoryOrderRecentFirst)

        this.setState({
            wifiHistoryFiltered: wifiHistoryAux,
            filterWifiConnectionStatus: -1,
            filterWifiName: -1,
            filterDate: null,

        })
    }



    GetWifiDuration = (array, index) => {
        if (array.length > 1 && index > 0) {
            const prevElem = array[index - 1]
            const currentElem = array[index]
            const dateTime1 = moment(prevElem.fecha + " " + prevElem.hora, 'yyyy-MM-dd HH:mm s')
            const dateTime2 = moment(currentElem.fecha + " " + currentElem.hora, 'yyyy-MM-dd HH:mm s')
            return moment.duration(dateTime1.diff(dateTime2)).humanize()
        }
        return null
    }


    render() {
        const { t } = this.props; //función de traducción
        
        if (!this.state.wifiHistory) {

            return (
                <div className="row">
                    <div className="col-12">
                        <h3>{t('WifiHistory.title')}</h3>
                        <MyLoader text={t('WifiHistory.loading')}/>
                    </div>
                </div>
            )


        } else {

            if (this.state.wifiHistory.length == 0) return <ShowNoItemsYet size={this.state.wifiHistory.length} itemName={t('WifiHistory.itenName')} />

            return (
                <div onClick={() => resizeNavBar()}>

                    <div className="row ">
                        <div className="col-12 ">
                            <h3>{t('WifiHistory.title')}</h3>
                        </div>
                    </div>

                    <ShowFeatureNotSupported device={this.context.activeDevice} featureId={FEATURE_ID} />
                    <ShowTotalAndLastUpdate total={this.state.totalObjects} totalUnread={this.state.totalObjectsUnread} lastUpdate={this.state.lastUpdate} title={t('WifiHistory.titleee')} limitToLast={LIMIT_TO_LAST} fbuid={this.context.user.uid} isDemoUsr={this.context.user.isDemoUsr} imei={this.context.activeDevice.imei} featureId={FEATURE_ID}
                        handleDeleteFinish={() => this.setState({ wifiHistory: [], wifiHistoryFiltered: [], totalObjects: 0, totalObjectsUnread: 0 })} />


                    {/* FILTROS***** */}
                    <div className="col-12 my-1 bg-dark blueShadowrounded-lg p-2">

                        <div>
                            <SelectDate selected={this.state?.filterDate} lastUpdate={this.state.lastUpdate} onChange={this.handleCalendarChange} array={this.state.wifiHistory} />

                            <SelectWifiNameGroup groupWifiName={this.state.groupWifiName} handleOnChange={this.handleWifiNameChange} value={this.state.filterWifiName} featureGranted={this.state.featureGranted} />
                            <SelectWifiConnectionStatusGroup groupConnectionStatus={this.state.groupWifiConnectionStatus} handleOnChange={this.handleConnectionStatusChange} value={this.state.filterWifiConnectionStatus} featureGranted={this.state.featureGranted} />
                        </div>

                        <ButtonShowAll size1={this.state.wifiHistory.length} size2={this.state.wifiHistoryFiltered.length} handleShowAllClick={this.handleShowAllClick} />

                    </div>
                    {/* *********** */}


                    {/* FiltersResult  */}
                    <ShowFiltersResult filterValue={this.state.filterDate} filterResultSize={this.state.wifiHistoryFiltered.length} filterName="date" showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterWifiName} filterResultSize={this.state.wifiHistoryFiltered.length} filterName="wifi name" showAll={this.handleShowAllClick} />
                    <ShowFiltersResult filterValue={this.state.filterWifiConnectionStatus} filterResultSize={this.state.wifiHistoryFiltered.length} filterName="status" showAll={this.handleShowAllClick} />


                    {this.state.wifiHistoryFiltered.length > 10 &&
                        <IA_Component prompt={`${t("IA_Prompts.Wifi")}  ${JSON.stringify(this.state.wifiHistoryFiltered.slice(0, 500))}.`}
                            featureGranted={this.state.featureGranted} />
                    }

                    <div className="row">

                        <div className="col-12  mb-3">
                            {/* WIFI HISTORY-------- */}


                            <ButtonOrderFirst length={this.state.wifiHistoryFiltered.length} order={this.state.wifiHistoryOrderRecentFirst} handleOrderChange={this.handleOrderChange} />

                            <ul className="list-group list-group-flush" >
                                {this.state.wifiHistoryFiltered.slice(0, this.state.renderSize).map((wifi, index) => {

                                    wifi.duration = this.GetWifiDuration(this.state.wifiHistoryFiltered, index)
                                    if (this.state.filterWifiConnectionStatus != -1 || this.state.filterWifiName != -1) wifi.duration = null//Fuera de estos casos no tiene sentido la medicion de duracion

                                    return (
                                        <div key={index} >
                                            <GetCurrentDateFromArray array={this.state.wifiHistoryFiltered} index={index} itemName="connections events" />
                                            <ShowWifi
                                                wifi={wifi}
                                                featureGranted={this.state.featureGranted}
                                                deleteObject={this.deleteObject}

                                            />
                                        </div>
                                    )
                                })}
                            </ul>
                            <ButtonGoUp arrayLength={this.state.wifiHistoryFiltered.length} maxLength={5} />
                            <ButtonShowMore arrayLength={this.state.wifiHistoryFiltered.length} renderSize={this.state.renderSize} handleShowMoreClick={this.handleShowMoreClick} />
                        </div>


                    </div>

                </div>
            )



        }
    }
}
WifiHistory.contextType = MiContexto
WifiHistory = withTranslation()(WifiHistory);
export default withRouter(WifiHistory);





function SelectWifiConnectionStatusGroup(props) {
    const { t } = useTranslation();

    const { groupConnectionStatus, value, featureGranted } = props

    return (
        <div className="input-group form-group my-0 p-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-bolt fa-1x "></i></span>
            </div>

            <select value={value} className="form-control miSelect" id="selectConnectionStatus" onChange={props.handleOnChange} >

                <option value={-1}>{t('WifiHistory.allHistory')}</option>

                {groupConnectionStatus.map((group, index) => {
                    let name = group.connectionStatus
                    if (!featureGranted) name = name.substring(0, 2) + t('WifiHistory.buySub')
                    return (
                        <option key={index} value={group.connectionStatus}>{name}  ({group.cant}) </option>
                    )
                })}
            </select>

        </div >


    )
}

function SelectWifiNameGroup(props) {
    const { t } = useTranslation();

    const { groupWifiName, value, featureGranted } = props

    return (
        <div className="input-group form-group my-0 p-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fa fa-wifi fa-1x "></i></span>
            </div>

            <select value={value} className="form-control miSelect" id="SelectWifiNameGroup" onChange={props.handleOnChange} >

                <option value={-1}>{t('WifiHistory.allWifi')}</option>
                {groupWifiName.map((group, index) => {
                    let wifiName = group.wifiName

                    if (!featureGranted) wifiName = wifiName.substring(0, 2) + t('WifiHistory.buySub')

                    return (
                        <option key={index} value={group.wifiName}>{wifiName}  ({group.cant}) </option>
                    )
                })}

            </select>
        </div>
    )
}


function ShowWifi(props) {
    const { t } = useTranslation();

    let { wifi, featureGranted } = props

    //------
    let key = wifi.key

    let bssid = wifi.bssid
    let statusName = wifi.connectionStatus
    let statusCode = wifi.statusCode
    let linkSpeed = wifi.linkSpeed
    let mac = wifi.mac
    let publicIp = wifi.publicIp
    let rssiLevel = wifi.rssiLevel
    let wifiIp = wifi.wifiIp
    let wifiName = wifi.wifiName
    let duration = wifi.duration

    let fecha = wifi.fecha
    let hora = wifi.hora
    let unRead = wifi.unRead
    //-------

    let blur=""
    if (!featureGranted) blur="divBlur"

    // if (featureGranted) {

        let bgColor = " bg-dark "; let faIcon = " "
        if (statusCode == -2) { bgColor = " bg-danger "; faIcon = "fa fa-power-off fa-2x mr-1" }
        if (statusCode == -1) { bgColor = " bg-success "; faIcon = "fa fa-power-off fa-2x mr-1" }
        if (statusCode == 0) { bgColor = " bg-warning "; faIcon = "fa fa-ban fa-2x mr-1" }
        if (statusCode == 1) { bgColor = " bg-primary "; faIcon = "fa fa-exchange fa-rotate-90  fa-2x mr-1" }
        if (statusCode == 2) { bgColor = " bg-white "; faIcon = "fa fa-wifi fa-2x mr-1 text-success" }

        //SE MUESTRA
        return (
            <Zoom duration={250}>
                <li className={"list-group-item " + bgColor + " py-1 " + blur}>
                    <div className="row mt-1">
                        <div className="col-12 text-dark">

                            {unRead && <span className="badge badge-danger">NEW!</span>}

                            <div> <i className={faIcon} />{statusName}</div>

                            {wifiName && !wifiName.includes("unknown") && <div className="text-success h4">{wifiName}</div>}
                            {publicIp && statusName != "Without Internet" && <div>IP: {publicIp}</div>}
                            {linkSpeed && <div>{t('WifiHistory.speed')}: {linkSpeed} </div>}
                            {rssiLevel && <div>{t('WifiHistory.signal')}: {rssiLevel}</div>}

                            {duration && statusName != "Power ON" && <span className='mt-1 badge badge-warning'><i className="fa fa-hourglass-end mr-1 mt-1 mb-0" />{duration}</span>}

                        </div>

                        <div className="col-12">
                            <div className="text-dark small d-flex justify-content-end">
                                <ShowItemDate fecha={fecha} hora={hora} />
                                <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => props.deleteObject(key)} />
                            </div>
                        </div>

                    </div>
                </li>
            </Zoom>

        )



    // } else {


    //     //DEMO******
    //     return (
    //             <Zoom >
    //                 <li className={"list-group-item bg-dark text-secondary py-1"}>
    //                     <div className="row mt-1">
    //                         <div className="col-12">

    //                             {unRead && <span className="badge badge-danger">NEW!</span>}

    //                             <BuySubscriptionTag />
    //                             {duration && statusName != "Power ON" && <div><i className="fa fa-hourglass-end mr-1 mt-1 mb-0" />{duration}</div>}
    //                         </div>

    //                         <div className="col-12">
    //                             <div className="small d-flex justify-content-end">
    //                                 <ShowItemDate fecha={fecha} hora={hora} />
    //                                 <i className=" ml-2 fa fa-trash-o text-danger " onClick={() => props.deleteObject(key)} />
    //                             </div>
    //                         </div>

    //                     </div>
    //                 </li>
    //             </Zoom>
    //     )
    // }


}